import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import { router } from "../_helpers";
import {
  userActions,
  plantActions,
  commonActions,
  homeActions,
  areaActions,
  structureActions,
  elementActions,
  pointActions,
  ensembleActions,
  alertActions,
  managementActions,
  widgetActions
} from "./actions";
import { userMutations, commonMutation } from "./mutations";
// import SHBOX from "@/main";

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== "production";

const initialDashboardSelection = {
  area: null,
  structure: null,
  element: null,
  point: null,
};

// APP global VUEX STATE
const state = function () {
  return {
    tenant: null,
    loggedIn: false,
    isLoading: false,
    caller: "",
    user: {},
    snackbar: { text: "", color: "" },
    breadcrumbs: [],
    dashboardTree: [],
    dashboardSelection: initialDashboardSelection,
    plantId: null,
  };
};

const getters = {
  loading: (state) => () => {
    return state.isLoading;
  },
};

// VUEX MUTATIONS
const mutations = {
  ...userMutations,
  ...commonMutation,

  ERROR: (state) => {
    state.loggedIn = false;
    state.user = {};
    state.machines = [];
    state.snackbar.color = "error";
    state.snackbar.text = "Connection error";
    console.log('going to login');
    router.push("/login");
  },

  DASHBOARD_SELECTION(state, payload) {
    state.dashboardSelection = payload;
  },

  UPDATE_DASHBOARD_TREE(state, payload) {
    state.dashboardTree = payload;
  },

  SET_PLANT(state, payload) {
    window.localStorage.setItem('plant', payload);
    state.plantId = payload;
  },
};

// VUEX ACTIONS
const actions = {
  ...userActions,
  ...plantActions,
  ...commonActions,
  ...homeActions,
  ...areaActions,
  ...structureActions,
  ...elementActions,
  ...pointActions,
  ...ensembleActions,
  ...alertActions,
  ...managementActions,
  ...widgetActions,

  _dashboardSelection: (context, payload) => {
    const [type] = [payload.type, payload.id];
    const selection = {
      ...initialDashboardSelection,
      [type]: payload.id,
    };

    context.commit("DASHBOARD_SELECTION", selection);
  },

  _updateDashboardTree: (context, payload) => {
    payload = payload || [];
    context.commit("UPDATE_DASHBOARD_TREE", payload);
  },

  _setPlant: (context, payload) => {
    context.commit("SET_PLANT", payload);
  },
};

const vuexLocalStorage = new VuexPersist({
  key: "vuex",
  storage: localStorage,
});

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {},
  strict: debug,
  plugins: [vuexLocalStorage.plugin],
});
