import api from "@/_helpers/api";

export default {
    _getDashboards: async (context, args) => {
      const response = await api.call({
        url: `/plant/${context.state.plantId}/widget/dashboard`,
        method: "get",
        query: args
      });

      if (!response) return;

      const { result, data } = response;

      if (!result) return;

      return data;
    },
    _createDashboard: async (context, args) => {
      const response = await api.call({
        url : `plant/${context.state.plantId}/widget/dashboard`, 
        method: 'post',
        data : args,
        hideMsg: true
      });
      
      if (!response) return;
  
      const { result, data } = response;
  
      if (!result) return;
  
      return data;
    },
    _getWidgets: async (context, args) => {
      const response = await api.call({
        url: `/plant/${context.state.plantId}/widget`,
        method: "get",
        query: args
      });
  
      if (!response) return;
  
      const { result, data } = response;
  
      if (!result) return;
  
      return data;
    },
    _addWidget: async (context, args) => {
      const response = await api.call({
        url : `/plant/${context.state.plantId}/widget`, 
        method: 'post',
        data: args
      });

      if (!response) return;
  
      const { result, data } = response;
  
      if (!result) return;
  
      return data;
    },
    _updateWidget: async (context, args) => {
      const response = await api.call({
        url : `/plant/${context.state.plantId}/widget/${args.widgetId}`, 
        method: 'put',
        data: {
          width: args.w,
          height: args.h,
          x: args.x,
          y: args.y
        },
        hideMsg: true
      });

      if (!response) return;
  
      const { result, data } = response;
  
      if (!result) return;
  
      return data;
    },
    _deleteWidget: async (context, widgetId) => {
      const response = await api.call({
        url: `plant/${context.state.plantId}/widget/${widgetId}`,
        method: 'delete',
      });

      if (!response) return;
  
      const { result, data } = response;
  
      if (!result) return;
  
      return data;
    },
    _getEnsembles: async (context) => {
      const response = await api.call({
        url: `plant/${context.state.plantId}/widget/ensembles`,
        method: 'get',
      });

      if (!response) return;
  
      const { result, data } = response;
  
      if (!result) return;
  
      return data;
    },
}