<template>
  <v-card flat>
    <v-card-title primary-title>
      <v-icon left color="grey">mdi-access-point</v-icon>
      {{ $t("Sensors") }}
    </v-card-title>

    <v-card-text>
      <v-data-table
        v-model="selectedItems"
        :headers="customHeaders || headers"
        :items="points"
        item-key="label"
        show-select
        :loading="loading"
        loading-text="Loading... Please wait"
        :items-per-page="itemsPerPage || 10"
      >
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: [
    "itemsPerPage",
    "customHeaders",
    "selected",
    "updateSelected",
    "pointsType",
  ],
  data() {
    return {
      selectedItems: this.$props.selected || [],
      headers: [
        { text: "Sensor", align: "start", sortable: true, value: "label" },
        { text: "Type", align: "start", sortable: true, value: "sensorModel" },
      ],
      points: [],
      loading: false,
    };
  },
  watch: {
    selected: function (newVal) {
      this.selectedItems = newVal;
    },
    selectedItems: function (newVal) {
      if (this.$props.updateSelected) {
        this.$props.updateSelected(newVal);
      }
    },
  },
  methods: {
    async loadData({ area, element, structure, sensor, type }) {
      this.loading = true;

      if (this.pointsType && this.pointsType === "all") {
        this.points = await this.$store.dispatch("_getPoints");
      } else {
        const data = {
          areaId: area ? area.areaId : undefined,
          elementId: element ? element.elementId : undefined,
          structureId: structure ? structure.structureId : undefined,
          sensorModel: type || undefined,
          label: sensor || undefined,
        };
        this.points = await this.$store.dispatch("_getPlantPoints", data);
      }

      this.selectedItems= [];

      this.loading = false;
    },
    async onFilter({ area, element, structure, sensor, type }) {
      await this.loadData({
        area,
        element,
        structure,
        sensor,
        type,
      });
    },
  },
  created: function () {},
};
</script>
