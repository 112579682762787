<template>
  <div>
    <div @click="deleteWidget(data.widgetId)" class="sys-lc-delete-btn" title="Delete widget">
      <img src="../../plugins/sysCharts/images/ic_delete_24px_white.svg" class="sys-lc-btn">
    </div>

    <div :id="idTarget">
    </div>
    
  </div>
</template>

<style src="../../plugins/sysCharts/css/sys-lc-white.css"></style>

<script>
import SysLineChart from '../../plugins/sysCharts/src/SysLineChart';

 export default {
    name: 'Widget',
    props: [
      'idTarget',
      'deleteWidget',
      'data',
    ],
    data: function(){
      return {
       count : 0,
       widget: null
      }
    },
    mounted: function() {      
      setTimeout(()=> { //TODO:; togliere capire perchè senza non è ancora disbonible il target es: let target = d3.select(`#${this.#ref.getTarget()}`);
        this.loadChart()
      } ,200);
    },
    methods: {
      loadChart(){

        const baseUrl = (window.shbox2?.baseUrl ?? process.env.VUE_APP_API_URL).replace(/\/$/, '');

        const _storage = JSON.parse(localStorage.getItem("vuex"));  
        const _plantId = _storage.plantId;
        const _token = _storage.user.token;
        let _dateFrom = this.$date.now();
        let _dateTo = this.$date.now().plus({ days: 1 });

        // can manage both formats: 'yyyy-MM-dd' and milliseconds
        if(_storage.user.sessionParams){
          if(_storage.user.sessionParams.dateFrom){
            // string format yyyy-MM-dd
            if(!isNaN()){
              _dateFrom = this.$date.fromJSDate(new Date(_storage.user.sessionParams.dateFrom));
            // timestamp format
            } else {
              _dateFrom = this.$date.fromJSDate(new Date(_storage.user.sessionParams.dateFrom*1));
            }
          }

          if(_storage.user.sessionParams.dateTo){
            // string format yyyy-MM-dd
            if(!isNaN()){
              _dateTo = this.$date.fromJSDate(new Date(_storage.user.sessionParams.dateTo));
            // timestamp format
            } else {
              _dateTo = this.$date.fromJSDate(new Date(_storage.user.sessionParams.dateTo*1));
            }
          }
        }

        this.widget = new SysLineChart({
              target : this.idTarget,
              width: 850,
              height: 550,
              axisMode : this.data?.graphWidgetTypeId === 2 ? 2: 3, // type 2 => 2 axis; type 1 = 3 axis
              externalId: this.data.widgetId,
              menu: true,
              filter: true,
              path: './',  // TOFIX: get the right path for images
              baseUrl: baseUrl,
              plantId: _plantId, // TOFIX:  used only to correct wrong data from TW
              searchUrl: `${baseUrl}/plant/${_plantId}/widget/${this.data.widgetId}/data`,
              checkUrl: `${baseUrl}/plant/${_plantId}/widget/${this.data.widgetId}/getParams`,
              saveUrl: `${baseUrl}/plant/${_plantId}/widget/${this.data.widgetId}/params`,
              filterCallback: this.filterCallback,
              headers: [{Authorization: `Bearer ${_token}`}],
              externalParams :{
                plantId: _plantId,
                startDate : _dateFrom.toISO(),
                endDate : _dateTo.toISO()    
              }
        });
      },
      async filterCallback(){
        return this.data.ensembles;
      },
    },
  }

</script>
