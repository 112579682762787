<template>
  <div class="w-100 pa-5">
    <v-form>
      <v-row align="start">
        <v-col cols="5">
          <h1>{{ plant }}</h1>
        </v-col>
        <v-col cols="3">
          <v-checkbox label="Exclude info" v-model="excludeInfo"></v-checkbox>
        </v-col>
        <v-col cols="1" class="mt-5">
          <v-btn
            :disabled="zoom > 3"
            @click="zoom < 3 ? (zoom += 0.2) : ''"
            color="blue-darken-2"
            ><v-icon>mdi-magnify-plus-outline</v-icon></v-btn
          >
        </v-col>
        <v-col cols="1" class="mt-5">
          <v-btn
            :disabled="zoom < 0.3"
            @click="zoom > 0.3 ? (zoom -= 0.2) : ''"
            color="blue-darken-2"
            ><v-icon>mdi-magnify-minus-outline</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-form>

    <div
      ref="screenshotTarget"
      class="screenshot-target zoomable"
      :style="{ transform: `scale(${zoom})` }"
    >
      <img
        v-if="img"
        style="max-width: none"
        :src="`data:image/png;base64,${img}`"
      />
      <v-chip
        v-for="(sensor, index) in positionedSensors"
        :key="index"
        draggable
        label
        class="ma-2"
        :style="{
          backgroundColor: excludeInfo
            ? 'rgba(0,0,0,0.4)'
            : 'rgba(250, 50, 50,0.8)',
          position: 'absolute',
          left: `${sensor.posX - 10}px`,
          top: `${sensor.posY - 10}px`,
        }"
      >
        {{ sensor.label }}
      </v-chip>
    </div>
  </div>
</template>

<style scoped>
.zoomable {
  position: relative;
  transition: transform 0.3s ease;
  transform-origin: top left;
}
</style>

<script>
import { mapState } from 'vuex';
export default {
  name: 'PlantView',

  data() {
    return {
      plant: null,
      breadcrumbsItems: [
        {
          href: '/home',
          disabled: false,
          text: this.$t('home').toUpperCase(),
          to: '/home',
        },
        {
          href: '/plant_view',
          disabled: true,
          text: this.$t('plant_view').toUpperCase(),
        },
      ],
      img: null,
      zoom: 1,
      excludeInfo: false,
      positionedSensors: [],
    };
  },
  computed: {
    ...mapState(['user', 'plantId']),
  },
  created: async function () {
    this.getData();
  },
  watch: {
    plantId: function () {
      this.getData();
    },
  },
  methods: {
    getData: async function () {
      const response = await this.$store.dispatch(
        '_get2DInspectionData',
        this.plantId
      );
      this.plant = response.label;
      this.img = response.img;
      this.positionedSensors = response.sensors.filter((x) => x.posX && x.posY);
    },
  },
};
</script>
