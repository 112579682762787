var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100 pa-5"},[_c('v-form',[_c('v-row',{attrs:{"align":"start"}},[_c('v-col',{attrs:{"cols":"5"}},[_c('h1',[_vm._v(_vm._s(_vm.plant))])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{attrs:{"label":"Exclude info"},model:{value:(_vm.excludeInfo),callback:function ($$v) {_vm.excludeInfo=$$v},expression:"excludeInfo"}})],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"disabled":_vm.zoom > 3,"color":"blue-darken-2"},on:{"click":function($event){_vm.zoom < 3 ? (_vm.zoom += 0.2) : ''}}},[_c('v-icon',[_vm._v("mdi-magnify-plus-outline")])],1)],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"disabled":_vm.zoom < 0.3,"color":"blue-darken-2"},on:{"click":function($event){_vm.zoom > 0.3 ? (_vm.zoom -= 0.2) : ''}}},[_c('v-icon',[_vm._v("mdi-magnify-minus-outline")])],1)],1)],1)],1),_c('div',{ref:"screenshotTarget",staticClass:"screenshot-target zoomable",style:({ transform: `scale(${_vm.zoom})` })},[(_vm.img)?_c('img',{staticStyle:{"max-width":"none"},attrs:{"src":`data:image/png;base64,${_vm.img}`}}):_vm._e(),_vm._l((_vm.positionedSensors),function(sensor,index){return _c('v-chip',{key:index,staticClass:"ma-2",style:({
        backgroundColor: _vm.excludeInfo
          ? 'rgba(0,0,0,0.4)'
          : 'rgba(250, 50, 50,0.8)',
        position: 'absolute',
        left: `${sensor.posX - 10}px`,
        top: `${sensor.posY - 10}px`,
      }),attrs:{"draggable":"","label":""}},[_vm._v(" "+_vm._s(sensor.label)+" ")])})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }