<template>
      <v-card flat>
        <v-card-title primary-title>
          <v-icon left color="info">mdi-information</v-icon>
          {{ $t('info') }}
        </v-card-title>
        <v-card-text>
          <v-form 
            ref="form"
            v-model="valid"
            lazy-validation
            >
            <v-text-field label="Name" v-model="structureEdited.label"  :rules="[rules.required]"></v-text-field>
            <v-select label="Configurator"  item-text="name" item-value="id" v-model="structureEdited.configuratorId" :rules="[rules.required]" :items="configuratorItems" @change="updateConfigurator"></v-select>
            <v-select label="Structure Type"
                      item-text="name" item-value="id"
                      v-model="structureEdited.structureTypeId"
                      :rules="[rules.required]" :items="$script.structureTypes"></v-select>
            <v-select
              v-model="structureEdited.parentId"
              :items="areas"
              label="Area"
              dense
              item-text="label"
              item-value="areaId"
              :rules="[rules.required]"
            >
            </v-select>
            <div style="display:flex">
              <!-- <Date-picker :timestamp.sync="structureEdited.monitoringDateTimeStart" :label="'Start Date'"/> -->
            </div>
            <div style="display:flex">
              <!-- <Date-picker :timestamp.sync="structureEdited.healthyPeriodStartDate" :label="'Health Start Date'"/> -->
            </div>
            <div style="display:flex">
              <!-- <Date-picker :timestamp.sync="structureEdited.healtyPeriodEndDate" :label="'Health End Date'"/> -->
            </div>
            <v-combobox
                label="Location"
                v-model="geoLocation"
                :items="geoLocationResults"
                append-outer-icon="mdi-magnify"
                @update:search-input="geoLocation = $event"
                @click:append-outer="searchLocation"
                @change="updateCoords"
            ></v-combobox>
            <v-text-field
                label="Latitude"
                v-model="structureEdited.location.latitude"
                @change="updateCoords"
                :rules="[rules.required, rules.isNumber]"
            ></v-text-field>

            <v-text-field
                label="Longitude"
                v-model="structureEdited.location.longitude"
                @change="updateCoords"
                :rules="[rules.required, rules.isNumber]"
            ></v-text-field>
            <!-- <v-file-input label="Plant Image" truncate-length="15"></v-file-input> -->
          </v-form>
        </v-card-text>
          <v-card-actions>
            <div class="btnContainer">
              <v-btn :loading="loading" :disabled="loading || !valid" color="success" @click="save()"><v-icon>mdi-floppy</v-icon>&nbsp;Save</v-btn>
              <v-btn :loading="loading" :disabled="loading" color="secondary" @click="cancel()" class="ml-5"><v-icon>mdi-arrow-left</v-icon>&nbsp;Cancel</v-btn>
              <v-btn :loading="loading" :disabled="loading" color="error" class="ml-5" @click="deleteItem()"><v-icon>mdi-delete</v-icon>&nbsp;Delete</v-btn>
            </div>
          </v-card-actions>
      </v-card>
</template>
<style scoped>
  .btnContainer{
    z-index: 2;
  }
</style>

<script>
// import DatePicker from '@/components/shared/DatePicker';
import { mapState } from 'vuex';

import axios from "axios";
const OSM_SEARCH_API = process.env.VUE_APP_OSM_SEARCH_API;

export default {
  components: {
    // DatePicker,
  },
  props: [
    'structure',
    'onSave',
    'onDelete',
    'action',
    'updateConfigurator',
    'onCancel'
  ],
   computed: {
    ...mapState( [
      'user',
      'dashboardSelection'
    ]),
    rules () {
      return this.$script.formRules
    },
  },
  data: () => {
    return {
      valid: false,
      structureEdited : {
        location: {},
      },
      loading: false,
      configuratorItems : [],
      areas: [],
      geoLocation:'',
      geoLocationResults:[],
    }
  },
  watch: {
    structure: function ( newVal ) {
      this.structureEdited = newVal;
       if(!this.structureEdited.structureId){
        if(this.dashboardSelection && this.dashboardSelection.area){
          this.structureEdited.parentId =  this.dashboardSelection.area
        }

        this.geoLocation = ''
      }
    },
    action: function ( newVal ) {
      if(newVal !== 'view'){
        this.loadAreas()
        this.loadConfigurator()
      }
    },
    label : 'validateField',
    configurator : 'validateField',
    structureType : 'validateField',
  },
  created: function() {
    if(this.action !== 'view'){
      this.loadAreas()
      this.loadConfigurator()
    }
  },
  methods: {
    async save(){
      this.loading = true
      await this.onSave(this.structureEdited)
      this.loading = false
    },
    async deleteItem(){
      await this.onDelete()
    },
    validateField () {
      this.$refs.form.validate()
    },
    async loadConfigurator(){
      this.configuratorItems =  await this.$store.dispatch('_getMembers', { role: 'CONFIGURATOR' });
    },
     async loadAreas(){
      this.areas =  await this.$store.dispatch('_getAreas');
    },
    cancel(){
      this.loading = true
      this.onCancel()
      this.loading = false
    },

    /**
     * Searches for inserted location in Open Street Map API
     */
    searchLocation(){

      // need to use axios here instead this.$http becuse $http is a wrapper
      // build to manage Thingworx response
      axios.get(OSM_SEARCH_API + '/search?q=' + this.geoLocation + '&format=json')
          .then(r => {

            let results = [];
            r.data.forEach(element => {
              results.push({
                text: element.display_name,
                value: [element.lat*1, element.lon*1]
              })
            });

            this.geoLocationResults = results;
          })
          .catch(function(e){
            console.error(e);
          });

    },

    /**
     * Updates geo coords info on form and map
     */
    updateCoords(e){

      // if coming from combobox, lat and lon are passed in the event
      // otherwise, keep actual values
      if(e.value !== undefined){
        this.structureEdited.location.latitude =  e.value[0];
        this.structureEdited.location.longitude = e.value[1];
      }
      // update on map
      this.$emit('update:structure_coords', [
          this.structureEdited.location.latitude*1,
          this.structureEdited.location.longitude*1
      ]);
    },
  },
}
</script>
