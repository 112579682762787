import SysException from './SysException.js'
import SysLoader from './SysLoader.js'
import SysPopup from './SysPopup.js'
import SysTooltip from './SysTooltip.js'
import SysMenu from './SysMenu.js'
import SysFilter from './SysFilter.js'
import SysUtility from './SysUtility.js'
import SysDataManager from './SysDataManager.js'
import SysNote from './SysNote.js'
import SysAlert from './SysAlert.js'
import SysEvent from './SysEvent.js'
import SysLine from './SysLine.js'
import SysPoint from './SysPoint.js'
import SysLegend from './SysLegend.js'
import SysAxis from './SysAxis.js'
import SysGrid from './SysGrid.js'

import * as d3 from 'd3'

/**
 * Creates a custom line chart
 * @class
 */
export default class SysLineChart {

  #name;
  #version;
  #externalId;
  #target;
  #svg;
  #xScale;
  #yScaleLeft;
  #yScaleRight;
  #dataset;
  #mergedDataset;
  #loader;
  #popup;
  #tooltip;
  #note;
  #alert;
  #event;
  #menu;
  #filter;
  #line;
  #point;
  #axis;
  #grid;
  #legend;
  #brush;
  #colorScheme;
  #groups;
  #axisMode;
  #transitionTime;
  #noteList;
  #hasMenu;
  #hasFilter;
  #dataManager;
  #configParams;
  #plantId;
  #baseUrl;
  #filterUrl; // used to get filter params
  #filterCallback; // used to get filter params
  #searchUrl; // used to get graph data
  #searchCallback;
  #checkUrl;  // used to check any previously saved params
  #checkCallback;
  #saveUrl;   // used to save params
  #saveCallback;
  #path;      // external path
  #increasePerc; // percentage used to increase scale domain
  #headers;
  #externalParams;
  /**
   * Creates a new graph
   * @param {Object} params - list of allowed params: 
   *  margin {Object} - sets graph margins {top, bottom, left, right}
   *  width {Number} - graph width
   *  height {Number} - graph height
   *  timeInFormat {String} - date format used for incoming data. Default to %Q (timestamp) 
   *  timeOutFormat {String} - date format used fort output strings. Default to %Y-%m-%d %H:%M
   *  externalId {String} - id of the graph, must be unique  
   *  target {String} - id of HTML object used as target for graph
   *  menu {Boolean} - if true, loads menu
   *  filter {Boolean} - ìf true, loads filter
   *  transitionTime {Number} - time in milliseconds, used in transition effects
   *  filterUrl {String} - used to get filter params 
   *  searchUrl {String} - used to get graph data
   *  searchCallback
   *  checkUrl {String} - used to check any previously saved params
   *  checkCallback
   *  saveUrl {String} - used to save params
   *  saveCallback
   */
  constructor(params) {

    // public params
    this.margin = params.margin || { top: 50, right: 60, bottom: 30, left: 80 };
    this.innerWidth = params.width - this.margin.left - this.margin.right;
    this.innerHeight = params.height - this.margin.top - this.margin.bottom;
    this.outerWidth = params.width;
    this.outerHeight = params.height;
    this.timeInFormat = params.timeInFormat || "%Q";// "%Y-%m-%d %H:%M:%S";
    this.timeOutFormat = params.timeOutFormat || "%Y-%m-%d %H:%M";

    // private params
    this.#name = 'SysLineChart';
    this.#version = '0.0.5';
    this.#externalId = params.externalId || null;
    this.#svg = null;
    this.#target = params.target;
    this.#axisMode = params.axisMode || 2; // allowed values are 2 or 3
    this.#hasMenu = params.menu || false;
    this.#hasFilter = params.filter || false;
    this.#xScale = null;
    this.#yScaleLeft = null;
    this.#yScaleRight = null;
    this.#dataset = null;
    this.#mergedDataset = [];
    this.#loader = null;
    this.#popup = null;
    this.#tooltip = null;
    this.#note = null;
    this.#alert = null;
    this.#event = null;
    this.#menu = null;
    this.#filter = null;
    this.#point = null;
    this.#axis = null;
    this.#grid = null;
    this.#legend = null;
    this.#brush = null;
    this.#colorScheme = null;
    this.#groups = [];
    this.#transitionTime = params.transitionTime || 500;
    this.#noteList = null;
    this.#configParams = params.configParams || {
      rescale: {
        left: {
          min: 0,
          max: 100
        },
        right: {
          min: 0,
          max: 100
        }
      },
      opacity :{
        alert : 1,
        event: 1,
        note: 1
      },
      query:{
        url: null,
        params: null
      },
      filter:{
        familyList : [],
        ensembleList : [],
        measureList: []
      },
      pointRadius: null,
      lineStroke: null,
      colorPalette: d3.schemeSet2,
      noteList: []      
    };
    this.#plantId = params.plantId || null;
    this.#baseUrl = params.baseUrl || null;
    this.#filterUrl = params.filterUrl || null;
    this.#filterCallback = params.filterCallback || null;
    this.#searchUrl = params.searchUrl || null;
    this.#searchCallback = params.searchCallback || null;
    this.#checkUrl = params.checkUrl || null;
    this.#checkCallback = params.checkCallback || null;
    this.#saveUrl = params.saveUrl || null;
    this.#saveCallback = params.saveCallback || null;
    this.#path = params.path || "./";
    this.#increasePerc = 1;
    this.#headers = params.headers || null;
    this.#externalParams = params.externalParams || null;

    this.#dataManager = new SysDataManager({
      remoteCheckUrl: this.#checkUrl,
      remoteSaveUrl: this.#saveUrl,
      saveCallback: this.#saveCallback,
      checkCallback: this.#checkCallback,
      headers: this.#headers,
      configParams: this.#configParams
    });

    this.init();

  }

  /*********************************** UTILS ************************************** */
  /**
   * Converts an array with two values(min, max), dilating the range by a percent value
   * Used in array.map function
   * @param {Number} num - Number to treat  
   * @param {Integer} i array index
   * @returns {Array} [min reduced, max augmented] 
   */
  dilateByPerc(num,i){

    let mod, m, r;

    if(!isNaN(num)){
      
      // covert only first two values
      if(i == 0){
        mod = num*1 - ( Math.abs(num) / 100 * this.#increasePerc);
      } else if(i == 1) {
        mod = num*1 + ( Math.abs(num) / 100 * this.#increasePerc);
      } else {
        mod = num*1;
      }
      
      m = Number((Math.abs(mod) * 100).toPrecision(15));
      r = Math.round(m) / 100 * Math.sign(mod); 

    // it's not a number, return the value as is
    } else {
      r = num;
    }
    return r;

  }

  /*********************************** SETTERS ************************************** */

  /**
   * Sets color palette
   * @param {Array} scheme - D3.js color scheme
   */
  setColorScheme(scheme = d3.schemeSet2) {
    const _scheme = d3.scaleOrdinal() || scheme;

    this.#colorScheme = _scheme 
      .domain(this.#groups)
      .range(this.#configParams.colorPalette);
  }

  /**
   * Sets the axisMode (2 or 3 axis)
   * @param {Number} axisMode - number of axis (x included). Allowed values: 2, 3
   */
  setAxisMode(axisMode = 2) {
    try {
      if ([2, 3].indexOf(axisMode * 1) == -1) {
        throw new SysException(this.#name, 'Axis mode not allowed', axisMode);
      }
    } catch (e) {
      console.error(e.toString());
    }

    this.#axisMode = axisMode;
  }


  /*********************************** GETTERS ************************************** */

  /**
   * Returns current plugin version
   * @returns {String}
   */
  getVersion() {
    return this.#version;
  }

  /**
   * Returns current axis mode
   * @returns {Number}
   */
  getAxisMode() {
    return this.#axisMode;
  }

  /**
   * Returns D3.js x Scale
   * @returns {Object}
   */
  getXScale() {
    return this.#xScale;
  }

  /**
   * Returns D3.js y left Scale 
   * @returns {Object}
   */
  getYScaleLeft() {
    return this.#yScaleLeft;
  }

  /**
   * Returns D3.js y left Scale  
   * @returns {Object}
   */
  getYScaleRight() {
    return this.#yScaleRight;
  }

  /**
   * Returns graph id
   * @returns {String} 
   */
  getExternalId() {
    return this.#externalId;
  }

  /**
   * Returns HTML target id
   * @returns {String}
   */
  getTarget() {
    return this.#target;
  }

  /**
   * Returns D3.js SVG node
   * @returns {Object}
   */
  getSVG() {
    return this.#svg;
  }

  /**
   * Returns current group color
   * @param {String} group 
   * @returns {String} color
   */
  getGroupColor(group) {
    return this.#colorScheme(group);
  }

  /**
   * Returns Popup component
   * @returns {Object}
   */
  getPopup() {
    return this.#popup;
  }

  /**
   * Returns current dataset
   * @returns {Object}
   */
  getDataset() {
    return this.#dataset;
  }

  /**
   * Returns merged dataset (unique recordset with merged data from all results) 
   * @returns {Object}
   */
  getMergedDataset() {
    return this.#mergedDataset;
  }

  /**
   * Returns tooltip component
   * @returns {Object}
   */
  getTooltip() {
    return this.#tooltip;
  }

  /**
   * Returns D3.js brush object 
   * @returns {Object}
   */
  getBrush() {
    return this.#brush;
  }

  /**
   * Returns datamanager component 
   * @returns {Object}
   */
  getDataManager() {
    return this.#dataManager;
  }

  /**
   * Returns current configuration 
   * @returns {Object} 
   */
  getConfigParams() {
    return this.#configParams;
  }

  /**
   * Returns a specific configuration param
   * @param {String} paramName 
   * @returns {*}
   */
  getConfigParam(paramName) {
    return this.#configParams[paramName] !== undefined ? this.#configParams[paramName] : null;
  }

  /**
   * Return current filter url 
   * @returns {String}
   */
  getFilterUrl() {
    return this.#filterUrl;
  }

  getFilterCallback() {
    return this.#filterCallback;
  }

  /**
   * Return current search url 
   * @returns {String}
   */
  getSearchUrl() {
    return this.#searchUrl;
  }

  getSearchCallback() {
    return this.#searchCallback;
  }

  /**
   * Return current path 
   * @returns {String}
   */
  getPath() {
    return this.#path;
  }


  /*********************************** SETTINGS ************************************** */


  /**
   * Sets visibility on alerts 
   * @param {Boolean} opacity 
   */
  alertToggleVisibility(opacity) {
    this.#alert.toggleVisibility(opacity);
  }

  /**
   * Sets visibility on events
   * @param {Boolean} opacity 
   */
  eventToggleVisibility(opacity) {
    this.#event.toggleVisibility(opacity);
  }

  /**
   * Sets visibility on notes
   * @param {Boolean} opacity 
   */
  noteToggleVisibility(opacity) {
    this.#note.toggleVisibility(opacity);
  }

  /**
   * Sets point radius 
   * @param {Number} radius 
   */
  updatePointRadius(radius) {
    this.#point.updateRadius(radius);
  }

  /**
   * Sets line thickness
   * @param {Number} stroke 
   */
  updateLineStroke(stroke) {
    this.#line.updateStroke(stroke);
  }

  /**
   * Updates all configuration params
   * @param {Object} params 
   */
  updateConfigParams(params) {
    for (let paramName in params) {
      if (this.#configParams[paramName] !== undefined && (params[paramName] !== undefined && params[paramName] !== null)) {
        this.#configParams[paramName] = params[paramName];
      }
    }
  }


  /*********************************** RENDER ************************************** */

  /**
   * Inits svg node and gets saved data
   */
  init() {

    // create main panel
    this.#svg = d3.select(`#${this.#target}`)
      .append("svg")
      .attr("id", `slc_${this.#externalId}_SVG`)
      .attr("class", "sys-lc-panel")
      .attr("width", this.outerWidth)
      .attr("height", this.outerHeight)
      .append("g")
      .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`);

    // Add a clipPath: everything out of this area won't be drawn.
    this.#svg.append("defs").append("svg:clipPath")
      .attr("id", `slc_${this.#externalId}_clip`)
      .append("svg:rect")
      .attr("id", `slc_${this.#externalId}_clipRect`)
      .attr("width", this.innerWidth)
      .attr("height", this.innerHeight)
      .attr("x", 0)
      .attr("y", 0);

    // Add brushing
    // initialise the brush area: start at 0,0 and finishes at width,height: it means I select the whole graph area
    // Each time the brush selection changes, trigger the 'handleZoom' function
    this.#brush = d3.brushX()
      .extent([[0, 0], [this.innerWidth, this.innerHeight]])
      .on("end", e => this.handleZoom(e, "IN"))


    // init axis component
    this.#axis = new SysAxis({
      ref: this
    });

    // init grid component
    this.#grid = new SysGrid({
      ref: this
    });

    // init tooltip
    this.#tooltip = new SysTooltip({
      ref: this
    });

    // init line component
    this.#line = new SysLine({
      ref: this,
      brush: this.#brush
    });

    // init point component
    this.#point = new SysPoint({
      ref: this
    });

    // init alerts component
    this.#alert = new SysAlert({
      ref: this
    });

    // init events component
    this.#event = new SysEvent({
      ref: this
    });

    // init loader popup
    this.#loader = new SysLoader({
      ref: this
    });

    // init popup
    this.#popup = new SysPopup({
      ref: this
    });

    // init legend component
    this.#legend = new SysLegend({
      ref: this
    });

    // init notes
    // must be the last component to work properly
    this.#note = new SysNote({
      ref: this
    });

    // init filter
    if (this.#hasFilter) {
      this.#filter = new SysFilter({
        ref: this,
        mode: this.#axisMode === 3 ? "MULTIPLE_PARAMS" : "MULTIPLE_ENSEMBLES",
        filterUrl: this.#filterUrl,
        filterCallback: this.#filterCallback,
        searchUrl: this.#searchUrl,
        searchCallback: this.#searchCallback
      })
    }

    // init menu
    if (this.#hasMenu) {
      this.#menu = new SysMenu({
        ref: this
      });
    }

    const afterCheck = data => {

      const _data = data.data !== undefined ? data.data : data;
      // update local config params with saved ones
      this.updateConfigParams(data);

      const query = _data.query !== undefined  && Object.keys(_data.query).length > 0 ? 
      _data.query : 
      _data.params?.query;

      const filter = _data.filter !== undefined  && Object.keys(_data.filter).length > 0 ? 
      _data.filter : 
      _data.params?.filter;  

      // TODO: temporary, to fix old TW paths
      query.url = `${this.#baseUrl}/plant/${this.#plantId}/widget/${this.#externalId}/data`;
    

      query.params = {...query.params, ...filter};

      //console.log("query", query);
      //console.log("filter", filter);

      // load graph
      if (query) {
        if (this.#searchCallback){
          this.load(query.url, query.params, this.#searchCallback);
        } else {
          this.load(query.url, query.params);
        }
      }

      // preload saved filters, this can be done async
      // data will be stored in filter component, and used when menu is rendered
      if (this.#hasFilter) {
        this.#filter.updateFilters({
          familyList: filter?.familyList,
          ensembleList: filter?.ensembleList,
          measureList: filter?.measureList
        })
      }

      // menu params must be loaded after graph render
      // notes either must be updated after graph is rendered

    }

    // check if are there any previously saved data
    this.#dataManager.getParams(this.#checkUrl, this.#externalId, afterCheck);

  }


  /**
   * Loads data from given url
   * @param {String} url 
   * @param {Object} params 
   */
  async load(url, params = {}, callback) {
    // set loading layer
    this.#loader.open();

    this.#dataManager.saveParam(this.#externalId, "query.url", url);
    this.#dataManager.saveParam(this.#externalId, "query.params", params);

    // add external params for data query
    params = {...params, ...this.#externalParams};

    
    if(callback){
      const data = await callback(params)
      // if there's some data  
      if(data && data.length > 0){
        this.handleDataLoad(data);
      // no data to manage, for example during init, disable loader
      } else {
        this.#loader.close();
      }      
    }else{
      this.#dataManager.remoteCall("POST", url, params).then(data => {
        data = data.data ? data.data : data;
        
        // if there's some data  
        if(data && data.length > 0){
          this.handleDataLoad(data);
        // no data to manage, for example during init, disable loader
        } else {
          this.#loader.close();
        }
      });
    }
  }

  /**
   * Manages result from query load
   * @param {Object} data - Load return
   */
  handleDataLoad(data) {

    const action = this.#dataset === null ? 'INIT' : 'UPDATE';

    // update dataset
    this.setDataset(data);

    this.setColorScheme();

    // init scales
    // all these settings depend on dataset
    this.initXScale();

    this.initYScaleLeft();

    this.initYScaleRight();

    // preload menu params
    // when render is ready, update menu settings with saved params, if any
    if (this.#hasMenu) {
      this.#menu.updateConfigParams(this.#configParams);
    }

    // close loading layer
    this.#loader.close();

    // init
    if (action == 'INIT') {

      this.renderGraph();

      // update
    } else {

      this.updateGraph();
    }

    

    // render notes, if any
    if (this.#configParams["noteList"].length > 0) {
      this.#note.render(this.#configParams["noteList"]);
    }

  }


  /**
   * Manages fetch result, and sets the right dataset
   * @param {*} data 
   */
  setDataset(data) {

    if(data) {

      data = data.array ? data.array : data;

      // reset all datasets
      this.#dataset = [];
      this.#groups = [];
      this.#mergedDataset = [];

      // parse returned data to compatible format
      this.#dataset = this.mapData(data);

      // I need to merge all datasets, to get max and min from date and values
      this.#mergedDataset = this.createMergedDataset(this.#dataset);

    }
  }


  /**
   * Merge all datasets to get max and min from date and values
   * @param {Object} dataset 
   * @returns {Object} merged dataset
   */
  createMergedDataset(dataset) {
    let mergedDataset = [];

    // TOFIX: scrivere meglio
    for (let i = 0, l = dataset.length; i < l; i++) {
      for (let j = 0, k = dataset[i].data.length; j < k; j++) {
        mergedDataset.push(dataset[i].data[j]);
      }
    }

    return mergedDataset;
  }

  /**
   * Maps raw result set to a valid dataset
   * @param {Object} data 
   * @returns {Object}
   */
  mapData(data) {

    const that = this;

    return data.map((groupObj, index) => {

      // fix for groupName, cannot start with a number, because cannot be recognized by query selector
      let groupName = isNaN(groupObj.groupName.charAt(0)) ? groupObj.groupName : `_${groupObj.groupName}`;

      that.#groups.push(groupName);

      const result = {
        rootName: groupObj.rootName,
        groupName: groupName,
        fieldName: groupObj.fieldName,
        fieldUnit: groupObj.fieldUnit,
        sensorName: groupObj.sensorName || null,
        alerts: groupObj.alerts !== undefined && groupObj.alerts.length > 0 ? groupObj.alerts.map((d) => {
          return {
            group: groupName,
            name: d.name,
            property: d.property,
            operator: d.operator,
            limit: d.limit,
            min: d.min,
            max: d.max,
            value: d.value
          };
        }) : [],
        events: groupObj.events !== undefined  && groupObj.events.length > 0 ? groupObj.events.map((d) => {
          return {
            group: groupName,
            date: d.date,
            type: d.type
          };
        }) : [],
        data: groupObj.data !== undefined  && groupObj.data.length > 0 ?  groupObj.data.filter(d => {
          if (d.value === "" || d.value === null || d.value === undefined) {
            return false;
          }
          return true;
        }).map(d => {

          return {
            group: groupName,
            date: SysUtility.parseTime(that.timeInFormat, d.date),
            value: SysUtility.parseValue(d.value),
            type: d.type,
            axis: that.#axisMode == 2 ? 'left' : index === 0 ? 'left' : 'right'
          };
        }) : []
      };

      //console.log("result", result)
      return result;

    });

    
  }

  /**
   * Renders the graph for the first time
   */
  renderGraph() {

    // const that = this;

    this.#axis.drawAxis(this.#xScale, this.#yScaleLeft, this.#yScaleRight);

    this.#grid.drawGrids(this.#xScale, this.#yScaleLeft, this.#yScaleRight);

    this.#line.drawLines();

    this.#point.drawPoints();

    this.#alert.drawAlerts();

    this.#event.drawEvents();

    this.#legend.drawLegend();

    // double click to reset zoom
    this.#svg.on("dblclick", e => {
      this.handleZoom(e, "OUT");
    });

    // on right click add a note
    this.#svg.on("contextmenu", e => {
      e.preventDefault();

      // react on right-clicking
      this.#note.createNote(e);
    });

  }

  /**
   * Updates graph data on new data load
   */
  updateGraph() {

    // const that = this;

    // remove old lines and dots
    this.#line.clearAll();
    this.#point.clearAll();
    this.#legend.clearAll();
    this.#alert.clearAll();
    this.#event.clearAll();
    this.#note.clearAll();

    // reset domain for axis
    // in this case also rescale must be resetted
    const range = d3.extent(this.getYScaleDataset('left'), (d) => d.value).map(this.dilateByPerc, this);
    this.#configParams.rescale.left.min = range[0];
    this.#configParams.rescale.left.max = range[1];  

    this.#xScale.domain(d3.extent(this.#mergedDataset, (d) => d.date));
    this.#yScaleLeft.domain([this.#configParams.rescale.left.min, this.#configParams.rescale.left.max]);


    // update left label
    this.#axis.updateYAxisLeftLabel(`${this.#dataset[0].fieldName} ( ${this.#dataset[0].fieldUnit} )`);
    this.#axis.updateColorYAxisLeft(this.#axisMode == 2 ? "#f2f2f2" : this.#colorScheme(this.#dataset[0].groupName));

    // change the x grid
    this.#grid.updateXGrid(this.#xScale);

    // change the y grid left
    this.#grid.updateYGridLeft(this.#yScaleLeft);

    // change the x axis
    this.#axis.updateXAxis(this.#xScale);

    // change the left y axis
    this.#axis.updateYAxisLeft(this.#yScaleLeft);


    // show right axis
    if (this.#axisMode == 3 && this.#dataset[1] !== undefined) {

      // reset domain for y right axes
      // in this case also rescale must be resetted
      const range = d3.extent(this.getYScaleDataset('right'), (d) => d.value).map(this.dilateByPerc, this);
      
      this.#configParams.rescale.right.min = range[0];
      this.#configParams.rescale.right.max = range[1];

      this.#yScaleRight.domain([this.#configParams.rescale.right.min, this.#configParams.rescale.right.max]);

      // update right label
      this.#axis.updateYAxisRightLabel(`${this.#dataset[1].fieldName} ( ${this.#dataset[1].fieldUnit} )`);
      this.#axis.updateColorYAxisRight(this.#colorScheme(this.#dataset[1].groupName));

      // change the y grid right
      this.#grid.updateYGridRight(this.#yScaleRight);
      this.#grid.toggleVisbilityYGridRight(1);

      // change the right y axis
      this.#axis.toggleVisbilityYAxisRight(1);

      // if available, hide right axis
    } else {

      this.#axis.toggleVisbilityYAxisRight(0);
      this.#grid.toggleVisbilityYGridRight(0);
    }


    this.#alert.drawAlerts();

    this.#event.drawEvents();

    this.#line.drawLines();

    this.#point.drawPoints();

    this.#legend.drawLegend();

  }

  /**
   * Returns correct dataset, depending on axis mode and value axis (left, right)
   * @param {String} direction 
   * @returns {Object}
   */
  getYScaleDataset(direction) {

    switch (direction) {
      case 'left':
        return this.#axisMode == 2 ? this.#mergedDataset : this.#dataset[0].data;

      case 'right':
        return this.#dataset[1] !== undefined ? this.#dataset[1].data : [];
      
    }
  }


  /**
   * Inits D3.js x scale object 
   */
  initXScale() {
    this.#xScale = d3.scaleTime()
      .domain(d3.extent(this.#mergedDataset, (d) => d.date))
      .range([0, this.innerWidth]);
  }

  /**
   * Inits D3.js y left scale object
   */
  initYScaleLeft() {

    const range = d3.extent(
      this.getYScaleDataset('left'), 
      (d) => {
        return d.value;
      }).map(this.dilateByPerc, this);

    // update configuration with last result, load wins over saved config
    if (range !== undefined && range[0] !== undefined && range[1] !== undefined) {
      this.#configParams.rescale.left.min = range[0];
      this.#configParams.rescale.left.max = range[1];
    }

    this.#yScaleLeft = d3.scaleLinear()
      .domain([this.#configParams.rescale.left.min, this.#configParams.rescale.left.max])
      .range([this.innerHeight, 0]);
  }

  /**
   * Inits D3.js y right scale object
   */
  initYScaleRight() {

    const range = d3.extent(this.getYScaleDataset('right'), (d) => d.value).map(this.dilateByPerc, this);

    // update configuration with last result, load wins over saved config
    if (range !== undefined && range[0] !== undefined && range[1] !== undefined) {
      this.#configParams.rescale.right.min = range[0];
      this.#configParams.rescale.right.max = range[1];
    }

    this.#yScaleRight = d3.scaleLinear()
      .domain([this.#configParams.rescale.right.min, this.#configParams.rescale.right.max])
      .range([this.innerHeight, 0]);
  }

  /**
   * Updates y left scale domain
   * @param {Number} min 
   * @param {Number} max 
   */
  updateYScaleLeft(min, max) {
    this.#yScaleLeft.domain([min, max]);
  }

  /**
   * Updates y right scale domain
   * @param {Number} min 
   * @param {Number} max 
   */
  updateYScaleRight(min, max) {
    this.#yScaleRight.domain([min, max]);
  }

  /**
   * Creates a csv file from dataset
   * @returns {String} content string
   */
  createCsv() {

    const header = "sensor,ensemble,date,messageType,fieldName,value" + "\n";

    let data = this.#dataset.map(e => {

      return e.data.map(g => {
        return [e.sensorName, e.rootName, SysUtility.formatTime(this.timeOutFormat, g.date), g.type, e.fieldName, g.value].join(",");
      }).join("\n")

    }).join("\n");

    return "data:text/csv;charset=utf-8," + header + data;
  }

  /**
   * Downloads a file, on a given content
   * @param {String} content 
   * @param {String} fileName - extension excluded
   */
  downloadFile(content, fileName) {

    var link = document.createElement("a");
    link.setAttribute("href", encodeURI(content));
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".
    link.remove();
  }

  /**
   * Sets new group color
   * @param {String} group 
   * @param {String} color 
   */
  changeGroupColor(group, color) {

    // set new color on palette
    // get current group color
    const currColor = this.getGroupColor(group);

    // find color index on palette
    const colorIndex = this.#configParams.colorPalette.indexOf(currColor);

    // change color palette
    this.#configParams.colorPalette[colorIndex] = color;
    this.setColorScheme(this.#configParams.colorPalette);

    // change line and point color    
    this.#line.updateColor(group, color);
    this.#point.updateColor(group, color);

    // change label color
    this.#legend.updateColor(group, color);

    // 2 axis mode
    if (this.#axisMode === 2) {

      // change alert border color
      this.#alert.updateColor(group, color);

      // change event border color
      this.#event.updateColor(group, color);

      // 3 axis mode
    } else {

      // no need to alter alert and event colors, as the group is the same  

      // change axis legend color
      // choose which between left or right label has to be changed
      // left
      if (this.#groups.indexOf(group) === 0) {
        this.#axis.updateColorYAxisLeft(color);
        //right
      } else {
        this.#axis.updateColorYAxisRight(color);
      }
    }
  }


  /********************************** HANDLERS ************************************ */

  /**
   * A function that updates the chart rendering for given boundaries
   * @param {event} e 
   * @param {String} action - allowed values: IN, OUT
   */
  handleZoom(e, action) {

    // A function that set idleTimeOut to null
    var idleTimeout
    function idled() { idleTimeout = null; }

    // let dataset = this.#axisMode === 3 ? this.#dataset[0].data : this.#mergedDataset;

    // let targetObj = this.#svg.transition();

    // What are the selected boundaries?
    let extent = e.selection;

    switch (action) {
      case 'IN':

        // If no selection, back to initial coordinate. Otherwise, update X axis domain
        if (!extent) {
          if (!idleTimeout) return idleTimeout = setTimeout(idled, 350); // This allows to wait a little bit
          this.#xScale.domain([4, 8])
        } else {
          this.#xScale.domain([this.#xScale.invert(extent[0]), this.#xScale.invert(extent[1])])
          this.#line.getContainer().select(".brush").call(this.#brush.move, null) // This remove the grey brush area as soon as the selection has been done
        }

        break;
      case 'OUT':

        // reset domain on x grid
        this.#xScale.domain(d3.extent(this.#mergedDataset, (d) => d.date));

        break;
    }

    // change the x grid
    this.#grid.updateXGrid(this.#xScale);

    // change the x axis
    this.#axis.updateXAxis(this.#xScale);

    // update line
    this.#line.updateLines();

    // update dots
    this.#point.updatePoints();

    // update events
    this.#event.updateEvents();

    // on horizontal zoom, alerts should't be updated

    // update notes:
    this.#note.updateNotes();

  }

  /**
   * Rescales values axis (both left or right)
   * @param {String} action - Allowed values: RESCALE, RESET
   * @param {String} axis - Allowd values : LEFT, RIGHT
   * @param {Number} min - min value 
   * @param {Number} max - max value
   */
  handleRescale(action = "RESCALE", axis = "LEFT", min, max) {

    switch (axis) {
      case 'LEFT':

        if (action === "RESCALE") {

          // save rescale values before
          this.#configParams.rescale.left.min = min;
          this.#configParams.rescale.left.max = max;

        } else if (action === "RESET") {
          const range = d3.extent(this.getYScaleDataset('left'), (d) => d.value).map(this.dilateByPerc, this);

          // save rescale values before
          this.#configParams.rescale.left.min = range[0];
          this.#configParams.rescale.left.max = range[1];

        }
        this.updateYScaleLeft(this.#configParams.rescale.left.min, this.#configParams.rescale.left.max);

        // update the y grid
        this.#grid.updateYGridLeft(this.#yScaleLeft);

        // update the y axis
        this.#axis.updateYAxisLeft(this.#yScaleLeft);

        // update menu rescale fields;
        this.#menu.setRescaleFields(axis, this.#configParams.rescale.left.min, this.#configParams.rescale.left.max);

        // save config params
        this.#dataManager.saveParam(this.#externalId, "rescale.left.min", this.#configParams.rescale.left.min);
        this.#dataManager.saveParam(this.#externalId, "rescale.left.max", this.#configParams.rescale.left.max);

        break;

      case 'RIGHT':

        if (action === "RESCALE") {
          // save rescale values before
          this.#configParams.rescale.right.min = min;
          this.#configParams.rescale.right.max = max;

        } else if (action === "RESET") {
          const range = d3.extent(this.getYScaleDataset('right'), (d) => d.value).map(this.dilateByPerc, this);

          // save rescale values before
          this.#configParams.rescale.right.min = range[0];
          this.#configParams.rescale.right.max = range[1];

        }
        this.updateYScaleRight(this.#configParams.rescale.right.min, this.#configParams.rescale.right.max);

        // change the y grid
        this.#grid.updateYGridRight(this.#yScaleRight);

        // change the y axis
        this.#axis.updateYAxisRight(this.#yScaleRight);

        // update menu rescale fields;
        this.#menu.setRescaleFields(axis, this.#configParams.rescale.right.min, this.#configParams.rescale.right.max);

        // save config params
        this.#dataManager.saveParam(this.#externalId, "rescale.right.min", this.#configParams.rescale.right.min);
        this.#dataManager.saveParam(this.#externalId, "rescale.right.max", this.#configParams.rescale.right.max);

        break;
    }

    // update line
    this.#line.updateLines();

    // update dots
    this.#point.updatePoints();

    // update alerts
    this.#alert.updateAlerts();

    // events don't need to be updated in rescale

    // update notes
    this.#note.updateNotes();


  }

  /**
   * @todo
   */
  handleResize() {
    console.log("TODO");
  }

  /**
   * Manages alert setting action from menu 
   * @param {Boolean} opacity 
   */
  handleAlertToggle(opacity) {
    this.alertToggleVisibility(opacity);
    this.#dataManager.saveParam(this.#externalId, "opacity.alert", opacity * 1);
  }

  /**
   * Manages event setting action from menu 
   * @param {Boolean} opacity 
   */
  handleEventToggle(opacity) {
    this.eventToggleVisibility(opacity);
    this.#dataManager.saveParam(this.#externalId, "opacity.event", opacity * 1);
  }

  /**
   * Manages note setting action from menu 
   * @param {Boolean} opacity 
   */
  handleNoteToggle(opacity) {
    this.noteToggleVisibility(opacity);
    this.#dataManager.saveParam(this.#externalId, "opacity.note", opacity * 1);
  }

  /**
   * Manages point radius setting action from menu 
   * @param {Number} radius 
   */
  handlePointRadius(radius) {
    this.updatePointRadius(radius);
    this.#dataManager.saveParam(this.#externalId, "pointRadius", radius * 1);
  }

  /**
   * Manages line stroke setting action from menu 
   * @param {Number} stroke 
   */
  handleLineStroke(stroke) {
    this.updateLineStroke(stroke);
    this.#dataManager.saveParam(this.#externalId, "lineStroke", stroke * 1);
  }

  /**
   * Manages group color change from tooltip 
   * @param {String} group 
   * @param {String} color 
   */
  handleGroupColorChange(group, color) {
    this.changeGroupColor(group, color);
    this.#dataManager.saveParam(this.#externalId, "colorPalette", this.#configParams.colorPalette);
  }

  /**
   * Manages search action from filter 
   * @param {String} url - data search url  
   * @param {Object} params - query params
   */
  handleFilter(url, params, callback) {
    if(url !== null && url !== undefined) {
      this.load(url, params, callback);
      this.#dataManager.saveParam(this.#externalId, "filter.familyList", params.familyList);
      this.#dataManager.saveParam(this.#externalId, "filter.ensembleList", params.ensembleList);
      this.#dataManager.saveParam(this.#externalId, "filter.measureList", params.measureList);
    }
  }

  /**
   * Handles note insert action from note component
   * @param {String} noteId - note random id
   * @param {Number} xCoord - x coord on graph
   * @param {Number} yCoord - y coord on graph
   * @param {String} content - note content 
   * @param {Number} xData - fake date, obtained from D3.js and used to render the note in the right place 
   * @param {Number} yData - fake value, based on left y axis, obtained from D3.js and used to render the note in the right place
   */
  handleNoteInsert(noteId, xCoord, yCoord, content, xData, yData) {
    this.insertNote(noteId, xCoord, yCoord, content, xData, yData);
  }

  /**
   * Handles note update from note component
   * @param {String} noteId - note random id
   * @param {Number} xCoord - x coord on graph
   * @param {Number} yCoord - y coord on graph
   * @param {String} content - note content 
   * @param {Number} xData - fake date, obtained from D3.js and used to render the note in the right place 
   * @param {Number} yData - fake value, based on left y axis, obtained from D3.js and used to render the note in the right place
   */
  handleNoteUpdate(noteId, xCoord, yCoord, content, xData, yData) {
    this.updateNote(noteId, xCoord, yCoord, content, xData, yData);

  }

  /**
   * Handles note delete from note component
   * @param {*} noteId - note random id
   */
  handleNoteDelete(noteId) {
    this.deleteNote(noteId);
  }


  /********************************** NOTES ************************************ */

  /**
   * Inserts new note 
   * @param {String} noteId - note random id
   * @param {Number} xCoord - x coord on graph
   * @param {Number} yCoord - y coord on graph
   * @param {String} content - note content 
   * @param {Number} xData - fake date, obtained from D3.js and used to render the note in the right place 
   * @param {Number} yData - fake value, based on left y axis, obtained from D3.js and used to render the note in the right place
   * @returns {String} noteId
   */
  insertNote(noteId, xCoord, yCoord, content, xData, yData) {
    // nodeId is created randomly by note component
    // create the record, adding query params for key

    let note = {
      noteId,
      xCoord,
      yCoord,
      xData,
      yData,
      content
    }

    // add record to memory
    this.#configParams["noteList"].push(note);
    this.#dataManager.saveParam(this.#externalId, "noteList", this.#configParams["noteList"]);

    // return the noteId
    return noteId;
  }

  /**
   * Updates note 
   * @param {String} noteId - note random id
   * @param {Number} xCoord - x coord on graph
   * @param {Number} yCoord - y coord on graph
   * @param {String} content - note content 
   * @param {Number} xData - fake date, obtained from D3.js and used to render the note in the right place 
   * @param {Number} yData - fake value, based on left y axis, obtained from D3.js and used to render the note in the right place
   */
  updateNote(noteId, xCoord, yCoord, content, xData, yData) {

    // get the record from memory
    let result = this.#configParams["noteList"].filter((element, index) => {
      // assign the index to each record
      this.#configParams["noteList"][index].index = index;
      return element.noteId === noteId;
    });

    // if record found, update it
    if (result.length === 1) {
      // grab the index, to modify directly on noteList
      let index = result[0].index;

      result[0].xCoord = xCoord * 1;
      result[0].yCoord = yCoord * 1;
      result[0].xData = xData,
        result[0].yData = yData,
        result[0].content = content;

      // overwrite new object upon old one
      this.#configParams["noteList"].splice(index, 1, result[0]);

      this.#dataManager.saveParam(this.#externalId, "noteList", this.#configParams["noteList"]);
    }

  }

  /**
   * Remove note from graph
   * @param {String} noteId  - note random id
   */
  deleteNote(noteId) {
    // remove note from memory

    // get the record from memory
    let result = this.#configParams["noteList"].filter((element, index) => {
      // assign the index to each record
      this.#configParams["noteList"][index].index = index;
      return element.noteId === noteId;
    });

    // if record found, update it
    if (result.length === 1) {
      // grab the index, to modify directly on noteList
      let index = result[0].index;

      // remove object from list
      this.#configParams["noteList"].splice(index, 1);

      this.#dataManager.saveParam(this.#externalId, "noteList", this.#configParams["noteList"]);

    }

  }
}