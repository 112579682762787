<template>
      <v-card flat>
        <v-card-title primary-title>
          <v-icon left color="info">mdi-information</v-icon>
          {{ $t('info') }}
        </v-card-title>

        <v-card-text>
          <v-form 
            ref="form"
            v-model="valid"
            lazy-validation
          >

            <v-text-field 
              label="Name" 
              v-model="areaEdited.label"
              :rules="[rules.required, rules.maxLength50]"
              :counter="50"
            ></v-text-field>

            <v-combobox 
              label="Location" 
              v-model="geoLocation" 
              :items="geoLocationResults"
              append-outer-icon="mdi-magnify" 
              @update:search-input="geoLocation = $event"
              @click:append-outer="searchLocation"
              @change="updateCoords"
            ></v-combobox>

            <v-text-field 
              type="number"  
              label="Latitude" 
              v-model.number="areaEdited.geoCoord.latitude"
              :rules="[rules.required, rules.isNumber]"
            ></v-text-field>

            <v-text-field 
              type="number"  
              label="Longitude" 
              v-model.number="areaEdited.geoCoord.longitude"
              :rules="[rules.required, rules.isNumber]"
            ></v-text-field>

            <v-slider 
              :label="'Radius ' + areaEdited.radius + ' km'" 
              min="1" 
              max="20" 
              step="1" 
              v-model="areaEdited.radius" 
              ticks
              :rules="[rules.required]"
            ></v-slider>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div class="btnContainer">
            <v-btn :loading="loading" :disabled="loading || !valid" color="success" @click="save()"><v-icon>mdi-floppy</v-icon>&nbsp;Save</v-btn>
            <v-btn :loading="loading" :disabled="loading" color="secondary" @click="cancel()" class="ml-5"><v-icon>mdi-arrow-left</v-icon>&nbsp;Cancel</v-btn>
            <v-btn :loading="loading" :disabled="loading" color="error" @click="deleteItem()" class="ml-5"><v-icon>mdi-delete</v-icon>&nbsp;Delete</v-btn>
          </div>
        </v-card-actions>
      </v-card>
      
</template>

<style scoped>
  .btnContainer{
    z-index: 2;
  }
</style>

<script>
import axios from 'axios';

const OSM_SEARCH_API = process.env.VUE_APP_OSM_SEARCH_API;

export default {
  props: [
    'area',
    'onSave',
    'onDelete',
    'onCancel'
  ],
  computed: {
    rules () {
      return this.$script.formRules
    },
  },
  data () {
    return {
      areaEdited: {
        geoCoord: {}
      },
      geoLocation:'',
      geoLocationResults:[],
      loading: false,
      valid: false,
    }
  },
  watch:{
    area: function(newVal){
      if(newVal && Object.keys(newVal).length){
        this.areaEdited = newVal
        this.geoLocation = ''
      }
    },
    name : 'validateField',
    latitude : 'validateField',
    longitude : 'validateField',
  },
  methods: {
    /**
     * Searches for inserted location in Open Street Map API
     */
    searchLocation(){
      // need to use axios here instead this.$http becuse $http is a wrapper 
      // build to manage Thingworx response
      axios.get(OSM_SEARCH_API + '/search?q=' + this.geoLocation + '&format=json')
      .then(r => {
        
        let results = [];
        r.data.forEach(element => {
          results.push({
            text: element.display_name,
            value: [element.lat*1, element.lon*1]
          })
        });
        
        this.geoLocationResults = results;
      })
      .catch(function(e){
        console.error(e);
      });
         
    },
    updateCoords(e){
      this.areaEdited.geoCoord.latitude =  e.value[0];
      this.areaEdited.geoCoord.longitude = e.value[1];
    }, 
    async save(){
      this.loading = true
      await this.onSave(this.areaEdited)
      this.loading = false
    },
    validateField () {
      this.$refs.form.validate()
    },
    async deleteItem(){
       this.onDelete()
    },
    async cancel(){
      this.loading = true
      await this.onCancel()
      this.loading = false
    },

  },

}
</script>
