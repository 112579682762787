import api from "@/_helpers/api";
import { setLanguage } from '@/lang/config';

export default {
  _perform_login: async (context) => {
    const { tenant } = context.state;
    if (!tenant || tenant.error) {
      console.error("Tenant not found");

      const error = tenant && tenant.error ||  {
        errorCode:  'SHF000',
        msg: 'login failed'
      }

      return {
        error
      };
    }

    if (location.href.indexOf('no-redirect') > -1) {
      return;
    }

    let url = tenant.loginUrl
    url +=url.indexOf('?') > -1 ? '&' : '?';
    url = `${url}returnUrl=${encodeURIComponent(window.location.href)}`;
    location.href = url;

  },

  _load_tenant_data: async (context) => {
    const response = await api.call({
      url: `auth/tenant-config?url=${encodeURIComponent(window.location.href)}`,
      method: "get",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) {
      const error = {
        errorCode: response.errorCode,
        msg: response.msg
      }
      context.commit("SET_TENANT", {error});
      return {
        error
      }
    }

    context.commit("SET_TENANT", data);

    setTimeout(() => {
      window.location.reload()
    }, 200);
    

    return data;
  },

  _fetch_token: async (context, {authKey, state}) => {
    try{
      const response = await api.call({
        url: `auth/generate-token?authKey=${authKey}&state=${state}`,
        method: "POST",
      });
  
      if (!response) return;
  
      const { result, data } = response;
  
      if (!result) {
        console.error(response)

        const msg = 'login failed' + (response.msg ? `: ${response.msg}` : '')

        return {
          error: {
            errorCode: response.errorCode,
            msg,
          }
        }
      }
      return data;
    }catch(e){
      console.error(e)
      return {
        error: {
          errorCode: 'SHF001',
          msg: 'login failed'
        }
      }
    }
  },
  _login: async (context, args) => {
    const { token, userData } = args.userLoginResponse;


    const lp = {
      login: {
        state: "success",
        tag: "login",
        msg: "",
        token: token,
        email1: userData.email1,
        email2: userData.email2,
        id: userData.id,
        configParams: userData.configParams,
        sessionParams: userData.sessionParams,
        firstName: userData.firstName,
        lastName: userData.lastName,
        name: userData.firstName,
        phone1: userData.phone1,
        phone2: userData.phone2,
        role: userData.role,
        display_name: `${userData.firstName} ${userData.lastName}`,
        user_name: userData.userName,
        plantIds: userData.plantIds,
        customerId: userData.customerId,
      },
    };

    if(lp.login.configParams && lp.login.configParams.language){
      setLanguage(lp.login.configParams.language)
    }

    context.commit("LOGIN_USER_DATA", { data: lp });

    let plantId = localStorage.getItem( 'plant' );

    if(plantId && !lp.login.plantIds.includes(plantId) && !lp.login.role === 'SUPERADMIN'){
      plantId = null;
    }

    if(!plantId){
      plantId = lp.login.plantIds[0]
    }

    context.commit("SET_PLANT",plantId );
  },
  _logout: (context) => {
    context.commit("LOGOUT_USER_DATA", {
      data: {
        logout: {
          state: "success",
          tag: "login",
          msg: "Logout",
        },
      },
    });
  },
  _getUser: async (context) => {
    const response = await api.call({
      url: `users/${context.state.user.id}`,
      method: "get",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    context.commit("UPDATE_USER_DATA", data);

    return data;
  },
  _updateUser: async (context, args) => {
    const response = await api.call({
      url: `users`,
      method: "put",
      data: args.data,
      hideMsg: args.hideMsg
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    context.commit("UPDATE_USER_DATA", data);

    return data;
  },
  _changePassword: async (context, args) => {
    const response = await api.call({
      url: `users/${context.state.user.id}/password`,
      method: "put",
      data: args,
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },
  _getMembers: async (context, args) => {
    const response = await api.call({
      url: `/users/members`,
      method: "get",
      query: { ...args, customerId: context.state.user.customerId },
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },
  _getUserPlants: async (context) => {
    const response = await api.call({
      url: `user/plants`,
      method: "get",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    context.commit("UPDATE_USER_PLANTS", data);

    return data;
  },
};
