import Vue from "vue";
import Router from "vue-router";
import store from "../stores/index";

import LoginPage from "@/components/login/LoginPage";
import User from "@/views/User";
import Config from "@/views/Config";
import Help from "@/views/Help";
import Home from "@/views/Home";
import TreeManagement from "@/views/TreeManagement";
// import Area from '@/views/dashboard/Area';
// import AreaEdit from '@/views/dashboard/AreaEdit';
// import Structure from '@/views/dashboard/Structure';
// import Element from '@/views/dashboard/Element';
// import Point from '@/views/dashboard/Point';
// import PointEdit from '@/views/dashboard/PointEdit';
import Dashboard from "@/views/Dashboard";
import WidgetsGraphs from "@/views/WidgetsGraphs";
import Ensembles from "@/views/Ensembles";
import EnsembleList from "@/views/ensembles/EnsembleList";
import Alerts from "@/views/Alerts.vue";
import SensorManagement from "@/views/SensorManagement";
import Setup from "@/views/sensorManagement/Setup";
import Export from "@/views/sensorManagement/Export";
import ExportImport from "@/views/ExportImport";
import PlantView from "@/views/PlantView";
import TreDEditor from "@/views/TreDEditor";

Vue.use(Router);

export const router = new Router({
  mode: "history",
  routes: [
    // otherwise redirect to home
    {
      path: "*",
      redirect: function () {
        return "/home";
      },
    },

    { path: "/user", component: User },
    { path: "/config", component: Config },
    { path: "/help", component: Help },
    { path: "/home", component: Home },
    { path: "/tree_management", component: TreeManagement },
    { path: "/dashboard", component: Dashboard },
    { path: "/dashboard/:section", component: Dashboard, props: true },
    {
      path: "/dashboard/:section/:entityId",
      component: Dashboard,
      props: true,
    },
    {
      path: "/dashboard/:section/:entityId/:action",
      component: Dashboard,
      props: true,
    },
    { path: "/widgets_graphs", component: WidgetsGraphs },
    { path: "/ensembles", component: Ensembles },
    {
      path: "/ensembles/list/:objectType?/:objectId?",
      component: EnsembleList,
    },
    { path: "/alerts", component: Alerts },
    { path: "/alerts/:action", component: Alerts, props: true },
    {
      path: "/alerts/:action/:entityType/:entityId?",
      component: Alerts,
      props: true,
    },
    { path: "/sensorManagement", component: SensorManagement },
    { path: "/sensorManagement/setup", component: Setup },
    { path: "/sensorManagement/export", component: Export },
    { path: "/export_import", component: ExportImport },
    { path: "/plant_view", component: PlantView },
    { path: "/3d_editor", component: TreDEditor },
    { path: "/login", component: LoginPage },
  ],
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login"];

  const authRequired = !publicPages.includes(to.path);

  const loggedIn = store.state.loggedIn;

  if (authRequired && !loggedIn) {
    return next({
      path: "/login",
      query: { returnUrl: to.path },
    });
  }

  next();
});
