<template>
   <div>
      <!-- PDD TODO -->
   </div>
</template>


<script>
export default {
  data () {
    return {
    }
  },
  methods: {
  },
  created: function() {
  }
}
</script>
