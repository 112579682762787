<template>

  <v-container class="fill-height" fluid style="opacity:0.9">
    <v-row align="center" justify="center">
      <v-col cols="4" sm="8" md="6" lg="4" >
        <v-card class="">

          <v-toolbar color="" flat>
            <v-img contain alt="SHbox" :src="require('../../assets/shbox_logo_min.png')" style="height:35px" />
            <v-spacer />
            <v-spacer />
            <sub class="black--text">Powered by</sub>&nbsp;
            <v-img contain alt="Sysdev" :src="require('../../assets/sysdev_logo.png')" style="height:35px"/>
          </v-toolbar>
          
          <v-card-text class="mb-5 d-flex flex-column align-center">
            <span class="headline">Loading tenant data...</span>
            <v-progress-linear
              indeterminate
              class="my-5"
            ></v-progress-linear>
          </v-card-text>


        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data () {
        return {
            loading: false
        }
    },
    computed: {
        ...mapState(['isLoading'])
    },
  mounted: async function () {
    await this.$store.dispatch('_load_tenant_data')
  }
};
</script>