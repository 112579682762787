<template>
  <v-container class="px-0">
    <Filters ref="filtersRef" :onFilter="onFilter" filtersType="2" />

    <v-row>
      <v-col cols="6">
        <Points ref="pointsRef" :updateSelected="updateSelected" />
      </v-col>

      <v-col cols="6">
        <v-card flat>
          <v-card-title primary-title>
            <v-icon left color="grey">mdi-cog</v-icon>
            {{ $t("setup") }}
          </v-card-title>

          <v-card-text>
            <v-form ref="formSetup" v-model="validSetup" lazy-validation>
              <v-text-field
                v-model="strainPositive"
                :label="
                  selectedType == 'LR-SS'
                    ? 'Strain Positive'
                    : selectedType == 'LR-SS-F'
                    ? 'Displacement Positive'
                    : 'Main Value Positive'
                "
                :rules="[rules.isNumber, rules.required]"
                @keypress="$script.filterNumber"
                type="number"
                step="any"
                min="0"
              >
              </v-text-field>

              <v-text-field
                v-model="strainNegative"
                :label="
                  selectedType == 'LR-SS'
                    ? 'Strain Negative'
                    : selectedType == 'LR-SS-F'
                    ? 'Displacement Negative'
                    : 'Main Value Negative'
                "
                :rules="[rules.isNumber, rules.required]"
                @keypress="$script.filterNumber"
                type="number"
                step="any"
                min="0"
              >
              </v-text-field>

              <v-text-field
                v-model="verticalTilt"
                label="Vertical Tilt"
                :rules="[rules.isNumber, rules.required]"
                @keypress="$script.filterNumber"
                type="number"
                step="any"
                min="0"
              >
              </v-text-field>

              <v-text-field
                v-model="acceleration"
                label="Acceleration"
                :rules="[rules.isNumber, rules.required]"
                @keypress="$script.filterNumber"
                type="number"
                step="any"
                min="0"
              >
              </v-text-field>

              <v-select
                v-if="selectedType == 'LR-SS' || selectedType == 'LR-SS-F'"
                label="Heartbeat"
                :rules="[rules.isNumber, rules.required]"
                :items="heartbeatAcquisitionInternalParams"
                v-model="heartbeat"
                item-text="text"
                item-value="value"
              ></v-select>

              <v-text-field
                v-else
                v-model="heartbeat"
                label="Heartbeat"
                :rules="[rules.isNumber, rules.required, $script.validateNumber(heartbeat, 1, 6)]"
                @keypress="$script.filterNumber"
                type="number"
                step="any"
                min="1"
                max="6"
              >
              </v-text-field>

                <v-select
                label="Acquisition Internal"
                :rules="[rules.isNumber, rules.required]"
                :items="heartbeatAcquisitionInternalParams"
                v-model="acquisitionInternal"
                item-text="text"
                item-value="value"
              ></v-select>
             
            </v-form>

            <div class="d-flex justify-end">
              <v-btn
                width="180"
                :disabled="
                  selectedPoints.length === 0 || !validSetup || loadingSetup
                "
                @click="sendSetup()"
                color="success"
              >
                <v-progress-circular
                  v-if="loadingSetup"
                  indeterminate
                  color="info"
                  :size="20"
                />
                <div class="d-flex align-center" v-else>
                  <v-icon>mdi-radio-tower</v-icon>&nbsp;Send Setup
                </div>
              </v-btn>
            </div>

            <v-form ref="formNull" v-model="validNull" lazy-validation>
              <v-checkbox
                :label="
                  selectedType === 'LR-SS' ? 'Null Strain' : selectedType === 'LR-SS-F' ? 'Null Displacement' : 'Null Main Value'
                "
                v-model="strain"
              ></v-checkbox>

              <v-checkbox label="Null Tilt" v-model="tilt"></v-checkbox>

              <div width="180" class="d-flex justify-end">
                <v-btn
                  width="180"
                  :disabled="
                    selectedPoints.length === 0 || !validNull || loadingSetup
                  "
                  @click="sendNull()"
                  color="success"
                >
                  <v-progress-circular
                    v-if="loadingSetup"
                    indeterminate
                    color="info"
                    :size="20"
                  />
                  <div class="d-flex align-center" v-else>
                    <v-icon>mdi-numeric-0-circle-outline</v-icon>&nbsp;Null
                    Procedure
                  </div>
                </v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import Filters from "@/components/shared/Filters";
import Points from "@/components/shared/Points";
import { mapState } from 'vuex';

export default {
  name: "Setup",
  components: {
    Filters,
    Points,
  },
  computed: {
    ...mapState(['plantId']),
    rules() {
      return this.$script.formRules;
    },
  },
  data() {
    return {
      breadcrumbsItems: [
        {
          href: "/home",
          disabled: false,
          text: this.$t("home").toUpperCase(),
          to: "/home",
        },
        {
          href: "/sensorManagement/setup",
          disabled: true,
          text: this.$t("sensor_management").toUpperCase(),
        },
      ],
      validSetup: false,
      validNull: false,
      strainPositive: null,
      strainNegative: null,
      verticalTilt: null,
      acceleration: null,
      heartbeat: null,
      acquisitionInternal: null,
      loadingSetup: false,
      strain: false,
      tilt: false,
      selectedPoints: [],
      selectedType: null,
      heartbeatAcquisitionInternalParams: [
        {
          text: "5 m",
          value: 5,
        },
        {
          text: "10 m",
          value: 10,
        },
        {
          text: "15 m",
          value: 5,
        },
        {
          text: "30 m",
          value: 30,
        },
        {
          text: "1 h",
          value: 60,
        },
        {
          text: "2 h",
          value: 120,
        },
        {
          text: "4 h",
          value: 240,
        },
        {
          text: "6 h",
          value: 360,
        },
        {
          text: "12 h",
          value: 720,
        },
        {
          text: "24 h",
          value: 1440,
        },
      ],
    };
  },
  watch: {
    plantId(newVal, oldVal) {
      if(newVal !== oldVal){
        this.onFilter({
          type: this.$refs.filtersRef.type,
          sensorSeries: "LR-SS",
      });
      }
    },
  },
  mounted: function () {
    this.$store.dispatch("_updateBreadcrumbs", this.breadcrumbsItems);
    this.onFilter({
      type: this.$refs.filtersRef.type,
      sensorSeries: "LR-SS",
    });
  },
  methods: {
    async onFilter({ area, element, structure, sensor, type, sensorSeries }) {
      this.$refs.pointsRef.onFilter({
        area,
        element,
        structure,
        sensor,
        type,
      });

      this.selectedType = sensorSeries;
    },
    async sendSetup() {
      if (!this.$refs.formSetup.validate()) return;

      this.loadingSetup = true;

      const params = {
        directionThreshold: parseFloat(this.verticalTilt),
        accelerationThreshold: parseFloat(this.acceleration),
        acquisitionInterval: parseFloat(this.acquisitionInternal),
      };

      if (this.selectedType === "LR-SS") {
        params.strainPositiveThreshold = parseFloat(this.strainPositive);
        params.strainNegativeThreshold = parseFloat(this.strainNegative);
      }

      if (this.selectedType === "LR-SSF") {
        params.displacementPositiveThreshold = parseFloat(this.strainPositive);
        params.displacementNegativeThreshold = parseFloat(this.strainNegative);
      }

      if (this.selectedType === "SHS") {
        params.mainValuePositiveThreshold = parseFloat(this.strainPositive);
        params.mainValueNegativeThreshold = parseFloat(this.strainNegative);
      }

      await this.$store.dispatch("_setManagementSetup", {
        data: {
          pointIdList: this.selectedPoints.map((x) => x.sensorId),
          params,
        },
      });

      this.loadingSetup = false;

      this.resetFields();
    },
    async sendNull() {
      if (!this.$refs.formNull.validate()) return;

      this.loadingSetup = true;

      const params = {
        flagsArePresent: true,
        directionIsToCalibrate: this.tilt,
      };

      if (this.selectedType === "LR-SS") {
        params.strainIsToCalibrate = this.strain;
      }

      if (this.selectedType === "LR-SSF") {
        params.displacementIsToCalibrate = this.strain;
      }

      await this.$store.dispatch("_setManagementCalibration", {
        data: {
          pointIdList: this.selectedPoints.map((x) => x.sensorId),
          params,
        },
      });

      this.loadingSetup = false;
    },
    updateSelected(items) {
      this.selectedPoints = items;
    },
    resetFields() {
      this.strainPositive = null;
      this.strainNegative = null;
      this.verticalTilt = null;
      this.acceleration = null;
      this.heartbeat = null;
      this.acquisitionInternal = null;
    },
  },
};
</script>
