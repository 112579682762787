<template>

  <v-container class="fill-height" fluid style="opacity:0.9">
    <v-row align="center" justify="center">
      <v-col cols="4" sm="8" md="6" lg="4" >
        <v-card class="pb-2">

          <v-toolbar color="" flat>
            <v-img contain alt="SHbox" :src="require('../../assets/shbox_logo_min.png')" style="height:35px" />
            <v-spacer />
            <v-spacer />
            <sub class="black--text">Powered by</sub>&nbsp;
            <v-img contain alt="Sysdev" :src="require('../../assets/sysdev_logo.png')" style="height:35px"/>
          </v-toolbar>
          
          <v-card-text class="mb-5  d-flex flex-column align-center">
            <span v-if="error" class="headline red--text">error: {{ error }}</span>
            <span class="headline red--text">{{ errorDescription }}</span>
            <v-progress-linear
              v-if="loading"
              indeterminate
              class="my-5"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex' 
export default {
    data () {
        return {
            showPsw: false,
            user_name: '',
            password: '',
            rules: this.$script.formRules,
            loading: true,
            error: '',
            errorDescription: ''
        }
    },
    computed: {
        ...mapState(['isLoading'])
    },
  mounted: async function () {
    this.loading = true;

    const urlParams = new URLSearchParams(window.location.search);

    const error = urlParams.get('error');
    const errorDescription = urlParams.get('error_description');

    if (error) {
      this.setError({errorCode: error, msg: errorDescription});
      return;
    }

    const authKey = urlParams.get('authKey');
    const state = urlParams.get('state');

    if (authKey && state) {
      await this.fetchToken(authKey, state);
    }else{
      this.performLogin();
    }
  },
  methods: {
    performLogin: async function () {
     const login = await this.$store.dispatch('_perform_login')
     if(login && login.error){
       this.setError(login.error);
     }
    },
    fetchToken: async function (authKey, state) {
      const userLoginResponse = await this.$store.dispatch('_fetch_token', {authKey, state: state});
      if(userLoginResponse.error){
       this.setError(userLoginResponse.error);
      }else{
        this.$store.dispatch('_login',{ userLoginResponse });
      }
      
    },
    setError(error){
      this.error = error.errorCode;
      this.errorDescription = error.msg;
      this.loading = false;
    }
  }
};
</script>