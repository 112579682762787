<template>
  <v-row justify="center">
    <v-dialog
      :value="show"
      overlay-opacity="0.4"
      overlay-color="#ffffff"
      persistent
    >
      <v-card flat>
        <v-overlay :absolute="true" :value="loading">
          <v-row align="center" justify="center">
            <v-col align="center">
              <v-progress-circular indeterminate color="info" :size="350">
              </v-progress-circular>
            </v-col>
          </v-row>
        </v-overlay>
        <v-card-title class="text-h5 blue darken-4 white--text">
          <v-toolbar-title>{{
            `${currentItem && currentItem.ensembleId ? "Edit" : "New"} Ensemble`
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="onCancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="6">
                <v-card flat>
                  <v-card-text>
                    <p class="text-h5 text--primary">
                      <v-icon left color="grey">mdi-cog</v-icon>
                      Ensemble info
                    </p>

                    <v-radio-group
                      :disabled="!!currentItem.ensembleId"
                      v-model="currentItem.objectType"
                      row
                      label="Object Type"
                      @change="changeObjectType"
                    >
                      <v-radio label="Structure" value="STRUCTURE"> </v-radio>
                      <v-radio label="Element" value="ELEMENT"> </v-radio>
                    </v-radio-group>

                    <v-select
                      :disabled="!!currentItem.ensembleId"
                      v-if="currentItem.objectType === 'STRUCTURE'"
                      v-model="object"
                      :rules="[rules.required]"
                      :items="structures"
                      label="Object"
                      item-text="label"
                      item-value="objectId"
                      return-object
                      @change="changeObject"
                    >
                    </v-select>
                    <v-select
                      :disabled="!!currentItem.ensembleId"
                      v-if="currentItem.objectType === 'ELEMENT'"
                      v-model="object"
                      :rules="[rules.required]"
                      :items="elements"
                      label="Object"
                      item-text="label"
                      item-value="objectId"
                      return-object
                      @change="changeObject"
                    >
                    </v-select>

                    <v-text-field
                      :rules="[rules.required]"
                      label="Name"
                      v-model="detail.label"
                    ></v-text-field>

                    <v-textarea
                      label="Description"
                      v-model="detail.description"
                    ></v-textarea>

                    <v-select
                      :disabled="!!currentItem.ensembleId"
                      v-model="selectedFamily"
                      :items="families"
                      label="Family"
                      item-text="algorithmFamily"
                      item-value="algorithmFamilyId"
                      @change="changeFamily"
                      return-object
                      :rules="[rules.required]"
                    >
                    </v-select>
                    <v-select
                      :disabled="!selectedFamily || !!currentItem.ensembleId"
                      v-model="selectedAlgorithm"
                      :items="algorithms"
                      label="Algorithm"
                      item-text="algorithm"
                      item-value="algorithmId"
                      return-object
                      @change="changeAlgorithm"
                      :rules="[rules.required]"
                    >
                    </v-select>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card flat>
                  <v-card-text>
                    <p class="text-h5 text--primary">
                      <v-icon left color="grey">mdi-cog</v-icon>
                      Parameters
                    </p>

                    <div v-if="selectedAlgorithm">
                      <div
                        v-if="
                          [
                            '3_SENSORS_ROSETTE',
                            '5_SENSORS_ROSETTE',
                            'MEAN_CURVATURE',
                          ].includes(selectedAlgorithm.algorithm)
                        "
                      >
                        <v-row>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="my-5 blue darken-4"
                                fab
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon dark> mdi-function-variant </v-icon>
                              </v-btn>
                            </template>
                            <v-img
                              v-if="
                                selectedAlgorithm.algorithm === 'MEAN_CURVATURE'
                              "
                              alt="Formula"
                              :src="
                                require('../../assets/MeanCurvature_AnalyticDefinition.png')
                              "
                            />
                            <v-img
                              v-else
                              alt="Formula"
                              :src="
                                require('../../assets/3SensorsRosette_AnalyticDefinition.png')
                              "
                            />
                          </v-tooltip>
                        </v-row>
                      </div>

                      <v-row
                        v-for="inputObject in selectedAlgorithm.inputObjects"
                        :key="`inputObject_${inputObject.id}`"
                      >
                        <v-data-table
                          v-if="inputObject.selection === 'MULTIPLE'"
                          v-model="selectedEntities"
                          :headers="[{ text: 'Entities', value: 'label' }]"
                          :items="
                            currentItem.ensembleId ? selectedEntities : entities
                          "
                          item-key="objectId"
                          :show-select="!currentItem.ensembleId"
                          :items-per-page="10"
                        />
                        <v-select
                          v-else
                          :disabled="!!currentItem.ensembleId"
                          v-model="inputObjects[inputObject.id]"
                          :items="entities"
                          item-text="label"
                          item-value="objectId"
                          item-disabled="disabled"
                          :label="inputObject.label"
                          :rules="[rules.required]"
                          @change="changeEntities"
                        />
                      </v-row>
                      <v-row
                        v-for="inputParam in selectedAlgorithm.inputParams"
                        :key="`inputParam${inputParam.id}`"
                      >
                        <v-text-field
                          v-if="
                            ['INTEGER', 'FLOAT', 'DOUBLE'].includes(
                              inputParam.type
                            ) && inputParam.name !== 'VALUES_RANGE'
                          "
                          :disabled="!!currentItem.ensembleId"
                          type="number"
                          step="any"
                          min="0"
                          :max="maxValue"
                          v-model.number="inputParams[inputParam.id]"
                          :label="inputParam.label"
                          :rules="[
                            rules.isNumber,
                            rules.required,
                            // inputParam.name === 'POINTS_TO_TRIGGER' && rules.maxValueTrigger(maxValue),
                          ]"
                          @keypress="$script.filterNumber"
                        />
                        <v-radio-group
                          v-else-if="
                            ['INTEGER', 'FLOAT', 'DOUBLE'].includes(
                              inputParam.type
                            ) && inputParam.name === 'VALUES_RANGE'
                          "
                          :disabled="!!currentItem.ensembleId"
                          v-model="inputParams[inputParam.id]"
                          :label="inputParam.label"
                        >
                          <v-radio label="ALL VALUE" :value="0"></v-radio>
                          <v-radio label="POSITIVE ONLY" :value="1"></v-radio>
                          <v-radio label="NEGATIVE ONLY" :value="2"></v-radio>
                        </v-radio-group>
                        <v-text-field
                          v-else-if="['STRING'].includes(inputParam.type)"
                          :disabled="!!currentItem.ensembleId"
                          v-model="inputParams[inputParam.id]"
                          :label="inputParam.label"
                          :rules="[rules.required]"
                        />
                        <v-textarea
                          v-else-if="['TEXT'].includes(inputParam.type)"
                          :disabled="!!currentItem.ensembleId"
                          v-model="inputParams[inputParam.id]"
                          :label="inputParam.label"
                          :rules="[rules.required]"
                        />
                        <v-switch
                          v-else-if="['BOOLEAN'].includes(inputParam.type)"
                          :label="inputParam.label"
                          :disabled="!!currentItem.ensembleId"
                          v-model="inputParams[inputParam.id]"
                          inset
                        />
                        <Date-picker
                          v-else-if="['DATE_TIME'].includes(inputParam.type)"
                          :timestamp.sync="inputParams[inputParam.id]"
                          :disabled="!!currentItem.ensembleId"
                          :label="inputParam.label"
                          :rules="[rules.required]"
                        />
                      </v-row>
                      <!-- rough one point -->
                      <!-- <div v-if="selectedAlgorithm.algorithm === 'ONE_POINT'">
                          <div v-if="currentItem.ensembleId && thermalStrain1">
                            <v-alert
                              class="mt-2"
                              dense
                              type="warning"
                              outlined
                            >
                              You didn't set any Thermal Strain params
                            </v-alert>
                            <Date-picker :timestamp.sync="startDate" :label="'Start Date'"/>
                             
                            <div class="d-flex justify-end">
                              <v-btn @click="setParams()" color="success">
                                Set Params
                              </v-btn>
                            </div>
                          </div>
                          <div v-if="currentItem.ensembleId && thermalStrain2">
                            <v-alert
                              class="mt-2"
                              dense
                              type="info"
                              outlined
                            >
                              You don't have enough data on this point to set Thermal strains params. Here's a recap <br>
                              The system will keep collecting data until the parameters are found 
                            </v-alert>
                             <v-data-table
                              :headers="headersThermalStrain"
                              :items="thermalStrainParams"
                              hide-default-footer
                            >
                            </v-data-table>
                            <div class="d-flex flex-row-reverse">
                              <v-btn @click="stopCollecting()" color="error">
                                Stop collecting data
                              </v-btn>
                            </div>
                          </div>
                           <div v-if="currentItem.ensembleId && thermalStrain3">
                            <v-alert
                              class="mt-2"
                              dense
                              type="success"
                              outlined
                              v-show="thermalStrainApplied"
                            >
                              You Have applied Thermal Strain correction on this ensemble
                            </v-alert>
                             <v-data-table
                              :headers="headersThermalStrainFull"
                              :items="thermalStrainParams"
                              hide-default-footer
                            >
                            </v-data-table>
                            <div :class="`d-flex ${!thermalStrainApplied? 'justify-space-between': 'justify-end'}  `">
                               <v-btn v-show="!thermalStrainApplied" @click="waitALongerPeriod()" color="success">
                                Wait a longer period
                              </v-btn>
                               <v-btn class="text-none" v-show="!thermalStrainApplied" @click="applyAlpha()" color="success">
                                APPLY α
                              </v-btn>
                              <v-btn @click="deleteParams()" color="error">
                                Delete params
                              </v-btn>
                            </div>
                          </div>
                        </div> -->
                      <!-- rough one point -->
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="!valid" @click="saveEnsemble()" color="success">
            <v-icon left>mdi-floppy</v-icon>Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import DatePicker from "@/components/shared/DatePicker";

export default {
  name: "AlertEdit",
  props: ["show", "onSave", "onCancel", "selectedItem"],
  components: {
    DatePicker,
  },
  watch: {
    selectedItem: function (newVal) {
      this.object = null;
      this.selectedFamily = null;
      this.selectedAlgorithm = null;
      this.thermalStrain1 = false;
      this.thermalStrain2 = false;
      this.thermalStrain3 = false;
      this.thermalStrainParams = [];
      this.inputObjects = {};
      this.inputParams = {};
      this.selectedEntities = [];

      this.loadData(newVal);
    },
    selectedEntities: function () {
      if (this.selectedAlgorithm) {
        const pointsToTrigger = this.selectedAlgorithm.inputParams.find(
          (x) => x.name == "POINTS_TO_TRIGGER"
        );

        if (pointsToTrigger) {
          this.maxValue = this.selectedEntities.length;
        }
      }
    },
  },
  computed: {
    filteredEntities() {
      return this.entities.filter((entity) => !entity.hidden);
    },
    rules() {
      return this.$script.formRules;
    },
  },
  data() {
    return {
      loading: false,
      currentItem: {},
      detail: {},
      types: [],
      families: [],
      familiesAll: [],
      familiesStructures: [],
      algorithms: [],
      object: null,
      selectedFamily: null,
      selectedAlgorithm: null,
      entities: [],
      inputObjects: {},
      inputParams: {},
      structures: [],
      elements: [],
      valid: false,
      minimumT: 0,
      maxValue: undefined,
      startDate: null,
      headersThermalStrain: [
        { text: "Start Date", value: "startDate" },
        { text: "T(min)", value: "Tmin" },
        { text: "T(max)", value: "Tmax" },
        { text: "ΔT", value: "DT" },
        { text: "n° of data", value: "nOfRowsAnalized" },
      ],
      headersThermalStrainFull: [
        { text: "Start Date", value: "startDate" },
        { text: "End Date", value: "endDate" },
        { text: "T(min)", value: "Tmin" },
        { text: "T(max)", value: "Tmax" },
        { text: "ΔT", value: "DT" },
        { text: "Pearson Index", value: "pearsonIndex" },
        { text: "αTS", value: "TS" },
        { text: "n° of data", value: "nOfDataInInterval" },
      ],
      thermalStrainApplied: false,
      thermalStrain1: false,
      thermalStrain2: false,
      thermalStrain3: false,
      thermalStrainParams: [],
      ranges: [
        { text: "All values", value: "ALL_VALUES" },
        { text: "Positive only", value: "POSITIVE_ONLY" },
        { text: "Negative only", value: "NEGATIVE_ONLY" },
      ],
      selectedEntities: [],
    };
  },
  methods: {
    async loadData(ensemble) {
      if (
        ensemble &&
        ensemble.ensembleId &&
        ensemble.ensembleId !== this.currentItem.ensembleId
      ) {
        this.currentItem = ensemble;
        this.detail = await this.$store.dispatch("_getEnsemble", {
          ensembleId: ensemble.ensembleId,
        });

        if (this.detail?.ensembleObjects) {
          this.detail.ensembleObjects.forEach((eo) => {
            //check se algorithmInputObjectId è di tipo multiplo aggiungere a inputObjects altrimenti a selectedEntities
            this.inputObjects[eo.algorithmInputObjectId] = eo.objectId;
            this.selectedEntities.push({ objectId: eo.objectId });
          });
        }

        if (this.detail?.ensembleParams) {
          this.detail.ensembleParams.forEach((ep) => {
            this.inputParams[ep.algorithmInputParamId] = ep.value;
          });
        }
      } else {
        this.detail = {};
        this.currentItem = {
          objectType: "STRUCTURE",
          ...ensemble,
        };
      }

      this.loading = true;

      if (this.currentItem.objectType === "STRUCTURE") {
        await this.loadStructure();
      } else {
        await this.loadElements();
      }

      await this.loadFamily();

      this.loading = false;
    },
    async loadStructure() {
      this.structures = await this.$store.dispatch("_getStructures");
      if (this.currentItem.objectId) {
        this.object = this.structures.find(
          (x) => x.structureId === this.currentItem.objectId
        );
      }
    },
    async loadElements() {
      this.elements = await this.$store.dispatch("_getElements");
      if (this.currentItem.objectId) {
        this.object = this.elements.find(
          (x) => x.elementId === this.currentItem.objectId
        );
      }
    },
    async loadEntities(entityType, algorithmId, objectId) {
      this.entities = [];
      this.loading = true;
      if (algorithmId && objectId) {
        this.entities = await this.$store.dispatch("getEnsembleEntities", {
          params: {
            algorithmId,
            objectId,
            entityType,
            algorithmName: this.selectedAlgorithm.algorithm,
          },
        });
        if (this.currentItem.ensembleId && this.selectedEntities.length) {
          this.selectedEntities = this.entities.filter((x) =>
            this.selectedEntities.some((y) => y.objectId === x.objectId)
          );
        }
      }

      this.loading = false;
    },
    async loadFamily() {
      this.loading = true;
      this.selectedFamily = null;

      this.familiesAll = await this.$store.dispatch("getEnsembleFamilies");
      this.familiesStructures = this.familiesAll.filter(
        (x) => x.algorithmFamily === "STATISTIC"
      );

      if (this.currentItem && this.currentItem.objectType === "STRUCTURE") {
        this.families = this.familiesStructures;
      } else {
        this.families = this.familiesAll;
      }

      if (this.detail.algorithmFamilyId) {
        this.selectedFamily = this.families.find(
          (x) => x.algorithmFamilyId === this.detail.algorithmFamilyId
        );
      }
      if (this.selectedFamily) {
        this.changeFamily(this.selectedFamily);
      }
      this.loading = false;
    },
    async loadAlgorithm(algorithmFamilyId) {
      this.loading = true;

      this.algorithms = await this.$store.dispatch("getEnsembleAlgorithms", {
        params: {
          algorithmFamilyId,
        },
      });

      if (this.detail.algorithmId !== null) {
        this.selectedAlgorithm = this.algorithms.find(
          (x) => x.algorithmId === this.detail.algorithmId
        );
        if (
          this.selectedAlgorithm &&
          this.selectedAlgorithm.algorithm === "ONE_POINT"
        ) {
          // this.checkThermalStrain(this.detail)
        }
      }

      if (this.selectedAlgorithm) {
        this.changeAlgorithm(this.selectedAlgorithm);
      }

      this.loading = false;
    },
    changeFamily(family) {
      this.selectedAlgorithm = null;
      this.loadAlgorithm(family.algorithmFamilyId);
    },
    async changeAlgorithm(algorithm) {
      if (this.object) {
        let entityType = algorithm.objectRelationships.find(
          (x) => x.objectType === this.currentItem.objectType
        );
        entityType = entityType ? entityType.entityType : "POINT";
        await this.loadEntities(
          entityType,
          algorithm.algorithmId,
          this.object.elementId || this.object.structureId
        );
      }

      const valuesRange = algorithm.inputParams.find(
        (x) => x.name === "VALUES_RANGE"
      );

      if (valuesRange) {
        this.inputParams[valuesRange.id] = 0;
      }
    },
    async saveEnsemble() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;

      if (this.currentItem.ensembleId) {
        await this.$store.dispatch("_updateEnsemble", {
          ensembleId: this.currentItem.ensembleId,
          data: {
            label: this.detail.label,
            description: this.detail.description,
          },
        });
      } else {
        let multipleEnsembleObjects = [];
        let ensembleInputObjects = [];

        ensembleInputObjects = Object.entries(this.inputObjects).map(
          ([k, v]) => ({
            algorithmInputObjectId: parseInt(k),
            objectId: v,
          })
        );

        const multipleAlgorithmInputObject =
          this.selectedAlgorithm.inputObjects.find(
            (x) => x.selection === "MULTIPLE"
          );

        if (multipleAlgorithmInputObject) {
          const elements = this.selectedEntities.map((x) => ({
            algorithmInputObjectId: multipleAlgorithmInputObject.id,
            objectId: x.objectId,
            label: x.label,
          }));
          if (this.selectedAlgorithm.algorithm === "ONE_POINT") {
            multipleEnsembleObjects.push(...elements);
          } else {
            ensembleInputObjects.push(...elements);
          }
        }

        const ensembleInputParams = Object.entries(this.inputParams).map(
          ([k, v]) => {
            const algorithmInputParamId = parseInt(k);
            const algorithmInputParam = this.selectedAlgorithm.inputParams.find(
              (x) => x.id === algorithmInputParamId
            );
            return {
              algorithmInputParamId,
              value: v,
              type: algorithmInputParam.type,
            };
          }
        );

        await this.$store.dispatch("_createEnsemble", {
          data: {
            label: this.detail.label,
            name: this.detail.label,
            description: this.detail.description,
            objectId: this.object.elementId || this.object.structureId,
            algorithmId: this.selectedAlgorithm.algorithmId,
            inputObjects: ensembleInputObjects,
            multipleEnsembleObjects: multipleEnsembleObjects,
            inputParams: ensembleInputParams,
          },
        });
      }

      this.$props.onSave();

      this.loading = false;
    },
    async changeObjectType(objectType) {
      this.selectedFamily = null;
      this.selectedAlgorithm = null;
      this.loading = true;
      if (objectType === "STRUCTURE") {
        await this.loadStructure();
        this.families = this.familiesStructures;
      }
      if (objectType === "ELEMENT") {
        await this.loadElements();
        this.families = this.familiesAll;
      }

      this.loading = false;
    },
    async changeObject(object) {
      this.loading = true;
      if (this.selectedAlgorithm) {
        let entityType = this.selectedAlgorithm.objectRelationships.find(
          (x) => x.objectType === this.currentItem.objectType
        );
        entityType = entityType ? entityType.entityType : "POINT";
        await this.loadEntities(
          entityType,
          this.selectedAlgorithm.algorithmId,
          object.elementId || object.structureId
        );
      }
      this.loading = false;
    },
    async loadThermalStrain() {
      this.thermalStrain1 = false;
      this.thermalStrain2 = false;
      this.thermalStrain3 = false;
      this.thermalStrainApplied = false;

      this.loading = true;

      const data = await this.$store.dispatch("_getEnsembleTsStatus", {
        ensembleId: this.currentItem.ensembleId,
      });

      console.log("ts ", data);

      // this.checkThermalStrain(data)

      this.detail = { ...this.detail, ...data };

      this.loading = false;
    },
    checkThermalStrain(detail) {
      const CONF = this.$store.state.user.configParams;
      console.log("checkThermalStrain", detail);
      const tsPramsLength = Object.keys(detail.tsParams).length;
      const runningTSParamsCollectionsLength = Object.keys(
        detail.runningTSParamsCollection
      ).length;

      if (runningTSParamsCollectionsLength > 0 && tsPramsLength === 0) {
        this.thermalStrain2 = true;
        this.thermalStrainParams = [
          {
            ...detail.runningTSParamsCollection,
            startDate: this.$date
              .fromMillis(detail.runningTSParamsCollection.startDate)
              .toFormat(CONF.dateFormat),
          },
        ];
      }

      if (runningTSParamsCollectionsLength === 0 && tsPramsLength > 0) {
        this.thermalStrain3 = true;
        this.thermalStrainParams = [
          {
            ...detail.tsParams,
            startDate: this.$date
              .fromMillis(detail.tsParams.StartPearsonIndexRangeDate)
              .toFormat(CONF.dateFormat),
            endDate: this.$date
              .fromMillis(detail.tsParams.EndPearsonIndexRangeDate)
              .toFormat(CONF.dateFormat),
          },
        ];

        let alfaToCheck = this.detail.currentAlphaTS;
        if (alfaToCheck === undefined) {
          alfaToCheck = this.detail.alphaTs;
        }
        console.log("alfaToCheck", alfaToCheck);
        if (alfaToCheck !== 0) {
          this.thermalStrainApplied = true;
        } else {
          this.thermalStrainApplied = false;
        }
      }

      if (runningTSParamsCollectionsLength === 0 && tsPramsLength === 0) {
        this.thermalStrain1 = true;
      }
    },
    async setParams(waitALongerPeriod) {
      this.loading = true;

      await this.$store.dispatch("_setEnsembleTs", {
        ensembleId: this.currentItem.ensembleId,
        data: {
          params: JSON.stringify({
            start: waitALongerPeriod || this.startDate,
            DTmin: waitALongerPeriod ? undefined : this.minimumT,
          }),
        },
      });

      this.loading = false;
      // this.loadThermalStrain()
    },
    async stopCollecting() {
      this.loading = true;
      await this.$store.dispatch("_ensembleTsStop", {
        ensembleId: this.currentItem.ensembleId,
      });
      this.loading = false;
      // this.loadThermalStrain()
    },
    async waitALongerPeriod() {
      this.setParams(this.detail.tsParams.StartPearsonIndexRangeDate);
    },
    async applyAlpha() {
      this.loading = true;
      await this.$store.dispatch("_ensembleTsApply", {
        ensembleId: this.currentItem.ensembleId,
      });
      this.loading = false;
      // this.loadThermalStrain()
    },
    async deleteParams() {
      this.loading = true;
      await this.$store.dispatch("_deleteEnsembleTs", {
        ensembleId: this.currentItem.ensembleId,
      });
      this.loading = false;
      // this.loadThermalStrain()
    },
    changeEntities() {
      this.entities = this.entities.map((obj) => ({
        ...obj,
        disabled: Object.values(this.inputObjects).includes(obj.objectId),
      }));
    },
  },
};
</script>
