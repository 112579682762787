import * as d3 from 'd3'
/**
 * Creates a custom data manager
 * @class
 */
export default class SysDataManager{

  #data;
  #localStorage;
  #remoteCheckUrl;
  #remoteSaveUrl;
  #saveCallback;
  #checkCallback;
  #headers;
  #configParams;
  

  /**
   * 
   * @param {Object} params - allowed params:
   *  remoteCheckUrl {String} - url used to check previously saved params
   *  remoteSaveUrl {String} - url used to save params
   *  saveCallback 
   *  checkCallback 
   */
  constructor(params = {}){

    this.#data = {};
    this.#remoteCheckUrl = params.remoteCheckUrl || null;
    this.#remoteSaveUrl = params.remoteSaveUrl || null;
    this.#localStorage = window.localStorage;
    this.#saveCallback = params.saveCallback || null;
    this.#checkCallback = params.checkCallback || null;
    this.#headers = params.headers || null;
    this.#configParams = params.configParams || null;
  }


  /**
   * Checks if there are any previously saved data. On async return executes callback function
   * @param {String} url Remote path
   * @param {Object} key Data item key
   * @param {Function} callback Return callback function
   */
   async getParams(url = this.#remoteCheckUrl, key, callback){


    // if url exists
    if(url !== null || callback!== null){
      
      // first try remote check...
      try {
        const configParams = await this.checkRemote(url, key)
        const keysArray = Object.keys(configParams.data.params);

        // console.log("configParams", configParams);

        if(configParams !== undefined && keysArray.length > 0){
          // console.log(111111)
          this.#configParams = configParams.data.params;
        } else {
          //console.log(222222)
          //this.#externalParams = this.checkLocal(key); 
        }

        callback(this.#configParams);      
      }catch(error){
        console.error(error);
        
        //this.#externalParams = this.checkLocal(key);
        // in case of error, try to get local data
        callback(this.#configParams); 
      }
    // then check on local only    
    } else {
      //this.#externalParams = this.checkLocal(key);
      // in case of error, try to get local data

      callback(this.#configParams);

    } 


  }

  /**
   * Saves data to local or remote storage 
   * @param {String} key Data item key 
   * @param {Object} content Json object
   */
  saveData(key, content){

    // first save local...
    //this.setLocalData(key, content);

    // ...then save remote 
    if(this.#remoteSaveUrl !== null && this.#remoteSaveUrl !== undefined){
      this.setRemoteData(this.#remoteSaveUrl, key, content);
    }  

    if(this.#saveCallback){
      this.#saveCallback({key, content})
    }
  }

  /**
   * 
   * @param {String} key Target item key
   * @param {String} paramName Param name to save 
   * @param {*} paramValue Param value
   */
  saveParam(key, paramName, paramValue){
    

    // console.log("this.#configParams", this.#configParams);


    if(paramName === "query.url")                 this.#configParams.query.url = paramValue;
    if(paramName === "query.params.familyList")   this.#configParams.query.params.familyList = paramValue;
    if(paramName === "query.params.ensembleList") this.#configParams.query.params.ensembleList = paramValue;
    if(paramName === "query.params.measureList")  this.#configParams.query.params.measureList = paramValue;
    if(paramName === "filter.ensembleList")       this.#configParams.filter.ensembleList = paramValue;
    if(paramName === "filter.familyList")         this.#configParams.filter.familyList = paramValue;
    if(paramName === "filter.measureList")        this.#configParams.filter.measureList = paramValue;
    if(paramName === "rescale.left.max")          this.#configParams.rescale.left.max = paramValue;
    if(paramName === "rescale.left.min")          this.#configParams.rescale.left.min = paramValue;
    if(paramName === "rescale.right.max")         this.#configParams.rescale.right.max = paramValue;
    if(paramName === "pointRadius")               this.#configParams.pointRadius = paramValue;
    if(paramName === "lineStroke")                this.#configParams.lineStroke = paramValue;
    if(paramName === "colorPalette")              this.#configParams.colorPalette = paramValue;
    if(paramName === "opacity.alert")             this.#configParams.opacity.alert = paramValue;
    if(paramName === "opacity.event")             this.#configParams.opacity.event = paramValue;
    if(paramName === "opacity.note")              this.#configParams.opacity.note = paramValue;
    if(paramName === "noteList")                  this.#configParams.noteList = paramValue;
    
    this.saveData(key, this.#configParams);
  }

  
  /**
   * Returns current data in memory
   * @returns Json object
   */
  getCurrentData(){
    return this.#data;
  }

  /**
   * Returns specific param in memory
   * @param {String} paramName Param name to get
   * @returns {*} any value, null if not found
   */
  getParam(paramName){
    return this.#configParams[eval(paramName)] !== undefined ? this.#configParams[eval(paramName)] : null;
  }

  /**
   * Checks if there are any previously saved data on remote storage
   * @param {String} url remote path
   * @param {String} key Target item key
   */
  async checkRemote(url, key){
    return await this.getRemoteData(url, key);
  }

  /**
   * Checks if there are any previously saved data on local storage
   * @param {String} key Data item key
   * @returns {Object} json object 
   */
  checkLocal(key){
    return this.getLocalData(key) || {};
  }

  /**
   * Gets data from remote url
   * @param {String} url remote path
   * @param {String} key Target item key   
   */
  getRemoteData(url, key){
    return this.remoteCall("POST",url, {widgetId:key}); 
    
  }

  /**
   * Saves data in a remote url
   */
  setRemoteData(url, key, content){

    this.remoteCall("POST",url, {
      widgetId: key, 
      params: content
    }); 
  }

  /**
   * Gets data from local storage
   * @param {String} key Data item key
   * @returns {Object} json object
   */
  getLocalData(key){
    return JSON.parse(this.#localStorage.getItem(key));  
  }

  /**
   * Saves data in a local storage
   * @param {String} key Data item key
   * @param {Object} content Data item content 
   */
  setLocalData(key, content){
    this.#localStorage.setItem(key, JSON.stringify(content));
  }

  /**
   * Clears selected item in local storage
   * @param {String} key Data item key
   */
  removeLocalData(key){
    this.#localStorage.removeItem(key);
  }

  /**
   * Clears all items in local storage
   */
  clearLocalData(){
    this.#localStorage.clear();
  }

  /**
   * 
   * @param {String} method values:POST(default), GET
   * @param {String} url remote path
   * @param {Object} params Json obgect containing query params
   * @returns d3.json Promise
   */
  remoteCall(method = "POST", url, params){

    let _headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }

    if(this.#headers !== null){
      this.#headers.forEach(element => {
        for(let prop in element){
          _headers[prop] = element[prop];
        }
      });
    }

    const result = d3.json(url,
      {
        method: method,
        headers:_headers,
        body:JSON.stringify(params),
        // mode: "no-cors",
      }
    )

    return result;
    
  }

}